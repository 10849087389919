import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAuth } from "../../app/hooks/useAuth"
import { PasswordField } from "../Field/PasswordField"

export const ResetPasswordForm = () => {
  const { id } = useParams();
  const { resetPassword, isLoading: loading } = useAuth();
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [userFormValues, setUserFormValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const { password, confirmPassword } = userFormValues;

  const handleChange = ({ target: { value, id } }) => {
    setUserFormValues({ ...userFormValues, [id]: value });
  };

  const validatePasswordMatch = (val) => {
    if (val === userFormValues.password) {
      setPasswordMismatch(false);
      setConfirmPasswordError("");
    } else {
      setPasswordMismatch(true);
      setConfirmPasswordError("Passwords do not match");
    }
  };

  const isValid = () => {
    // validatePassword(password);
    validatePasswordMatch(confirmPassword);

    return passwordError === "" && !passwordMismatch && id !== "";
  };

  useEffect(() => {
    if (passwordError) setPasswordError("");
  }, [password]);

  useEffect(() => {
    if (confirmPasswordError) setConfirmPasswordError("");
  }, [confirmPassword]);

  const handleResetPassword = async () => {
    analytics.track("reset_password_button_clicked");
    if (isValid()) {
      await resetPassword(userFormValues.password, id);
    }

    userFormValues.password
      ? setPasswordError("")
      : setPasswordError("Required");
    userFormValues.confirmPassword
      ? setConfirmPasswordError("")
      : setConfirmPasswordError("Required");
  };

  return (
    <div className="login-box">
      <div className="justify-center">
        <h1 className="text-center text-2xl leading-6 font-bold mb-7">
          Reset Password
        </h1>
        <div className="space-y-2 text-left">
          <div>
            <div className="space-y-4">
              <div className="text-left input-label-text">Password</div>
              <PasswordField
                id="password"
                value={password}
                onChange={handleChange}
                // onBlur={(e) => validatePassword(e.target.value)}
              />
            </div>
            <div className="text-sm text-primary-red h-4 mt-1">
              {passwordError}
            </div>
          </div>
          <div>
            <div className="space-y-4">
              <div className="text-left input-label-text">Confirm password</div>
              <PasswordField
                id="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
                onBlur={(e) => validatePasswordMatch(e.target.value)}
              />
            </div>
            <div className="text-sm text-primary-red h-4 mt-1">
              {(passwordMismatch || confirmPasswordError !== "") &&
                (confirmPasswordError || "Passwords do not match")}
            </div>
          </div>
        </div>
        {/* {resultStatus && (
          <div className="text-sm text-primary-red text-center h-5 mt-2">
            {resultStatus}
          </div>
        )} */}
        <button
          className="flex items-center justify-center mt-4 solid py-4 bg-price-green w-full btn font-semibold text-sm leading-3.5 text-black"
          onClick={handleResetPassword}
        >
          <div>Reset Password</div>
          {loading && (
            <svg
              className="animate-spin  h-5 w-5 text-white ml-4 absolute"
              style={{ left: "60%" }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx={12}
                cy={12}
                r={10}
                stroke="currentColor"
                strokeWidth={4}
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
        </button>
        <div className="flex space-x-2 justify-center items-center mt-7.5  text-center">
          <div className="font-bold">
            <a
              href="/login"
              onClick={() => analytics.track("back_to_login_clicked")}
            >
              Back to Log In
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
