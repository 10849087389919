import { useLearn } from "../../../app/hooks/useLearn";
import { AsssetDashLogo } from "../../AssetDashLogo";

export default function PreviewSlide() {
  const { title, description, uploadImage } = useLearn();

  const fileName =
    uploadImage && uploadImage.length > 0 && uploadImage[0]?.data_url
      ? uploadImage[0]?.data_url
      : uploadImage[0]
      ? uploadImage[0]
      : "";

  return (
    <div
      style={{
        height: "580.31px",
        // backgroundImage:
        //   "url('https://storage.googleapis.com/assetdash-prod-images/web_app/login-onboard-bg.png')",
      }}
      className="bg-cover bg-black py-4 px-4 rounded-sm text-left text-white flex flex-col"
    >
      <AsssetDashLogo />
      <div className="space-y-4 mt-8">
        {/* <p className="text-3xl lineClamp-3">{title}</p> */}
        <p className={`text-center font-bold text-sm min-h-120`}>
          {description}
        </p>
        <div>
          {fileName && (
            <img
              src={fileName}
              alt=""
              className="mx-auto w-full object-contain rounded-12 h-42"
            />
          )}
        </div>
      </div>
      <div className="mt-auto">
        <button
          className="btn text-black bg-price-green w-full py-2 mt-auto"
          disabled
        >
          Continue
        </button>
      </div>
    </div>
  );
}
