import AccordionBasic from "../../components/TailwindLibrary/AccordionBasic";

export default function QuestionDetails({ data }) {
  return (
    <div className="space-y-2">
      <h2 className="text-3xl text-slate-800 font-bold mb-6 font-inter">
        Questions
      </h2>
      {data.map((item) => {
        return (
          <div key={item.id}>
            <AccordionBasic title={item.question}>
              <ol className="list-[lower-alpha] list-inside mt-2">
                {item?.answer_a && (
                  <li
                    className={`py-2 px-4 rounded-8 ${
                      item.correct_answer === "a" ? "bg-price-green" : ""
                    }`}
                  >
                    {item.answer_a}
                  </li>
                )}
                {item?.answer_b && (
                  <li
                    className={`py-2 px-4 rounded-8 ${
                      item.correct_answer === "b" ? "bg-price-green" : ""
                    }`}
                  >
                    {item.answer_b}
                  </li>
                )}
                {item?.answer_c && (
                  <li
                    className={`py-2 px-4 rounded-8 ${
                      item.correct_answer === "c" ? "bg-price-green" : ""
                    }`}
                  >
                    {item.answer_c}
                  </li>
                )}
                {item?.answer_d && (
                  <li
                    className={`py-2 px-4 rounded-8 ${
                      item.correct_answer === "d" ? "bg-price-green" : ""
                    }`}
                  >
                    {item.answer_d}
                  </li>
                )}
              </ol>
            </AccordionBasic>
          </div>
        );
      })}
    </div>
  );
}
