import { useState } from "react"

function AccordionBasic(props) {
  const [open, setOpen] = useState(false);

  return (
    <div className="rounded-sm border border-slate-200 bg-white shadow-lg divide divide-y-1 divide-gray-text">
      <button
        className="flex px-4 py-2  items-center justify-between w-full group mb-1"
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        <div className="text-xl text-slate-800 font-bold">{props.title}</div>
        <svg
          className={`w-8 h-8 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 ${
            open && "rotate-180"
          }`}
          viewBox="0 0 32 32"
        >
          <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <div className={`px-4 pb-2  text-lg list-disc ${!open && "hidden"}`}>
        {props.children}
      </div>
    </div>
  );
}

export default AccordionBasic;
