import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tooltip } from '../TailwindLibrary/Tooltip';

const gameColor = {
  active: '#21CE99',
  not_live_yet: '#90EE90',
  created: '#F5AC6E',
  review: '#A8A8A8',
  inactive: '#E8444D',
};
const displayByGameStatus = {
  active: 'Active',
  not_live_yet: 'Not Live Yet',
  created: 'Created',
  review: 'Review',
  inactive: 'Inactive',
};

function GameCard(props) {
  const { data } = props || {};

  const getStatusColor = status => {
    return gameColor[status];
  };

  return (
    <div
      className="col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200"
      onClick={() =>
        analytics.track('game_card_clicked', {
          id: data.id,
        })
      }
    >
      <Link
        className="text-slate-800 hover:text-slate-900 mb-1"
        to={`/games/${data.id}`}
      >
        <div className="flex flex-col p-5 w-full h-full">
          <header>
            <div className="flex justify-between">
              <img
                src={data.image_url || data.partner_logo_url}
                className="rounded-full h-12 w-12 object-cover"
              />
              <div className="">
                <Tooltip
                  text={displayByGameStatus[data.status]}
                  position="bottom"
                >
                  <p
                    className={`capitalize font-bold w-4 h-4 rounded-full`}
                    style={{ backgroundColor: getStatusColor(data.status) }}
                  ></p>
                </Tooltip>
              </div>
            </div>
          </header>
          <div className="divide divide-y-1 divide-gray-text flex flex-col h-full justify-between">
            <div className="flex flex-col grow mt-2 mb-4">
              <p className="text-2xl font-bold text-black lineClamp-3 mb-2">
                {data?.name}
              </p>
              <h3 className="text-xl font-medium lineClamp-3 mb-2">
                {data?.description}
              </h3>
              <p className="text-md text-black lineClamp-3 mb-2">
                {data?.instructions}
              </p>
              <p className="text-md text-black lineClamp-3 mb-2">
                {data?.game_url}
              </p>
              <div className="text-sm mt-auto">
                <p>
                  <span className="font-semibold">Updated:</span>{' '}
                  {moment(data?.updated + 'z').fromNow()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default GameCard;
