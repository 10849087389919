import { createContext, useContext, useState } from "react"

export const SecretModeContext = createContext();

export function useSecretMode() {
  return useContext(SecretModeContext);
}

export const SecretModeProvider = ({ children }) => {
  const [secretMode, setSecretMode] = useState(false);

  const secretModeValue = "--.--";

  const handleSecretMode = () => {
    setSecretMode(!secretMode);
  };

  return (
    <SecretModeContext.Provider
      value={{ secretMode, handleSecretMode, secretModeValue }}
    >
      {children}
    </SecretModeContext.Provider>
  );
};
