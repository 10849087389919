export const CopyIcon = ({ direction, color }) => {
  return (
    <>
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.99944 9.71373H3.42801C3.12491 9.71373 2.83422 9.59332 2.61989 9.37899C2.40556 9.16467 2.28516 8.87398 2.28516 8.57087V3.42801C2.28516 3.12491 2.40556 2.83422 2.61989 2.61989C2.83422 2.40556 3.12491 2.28516 3.42801 2.28516H8.57087C8.87398 2.28516 9.16467 2.40556 9.37899 2.61989C9.59332 2.83422 9.71373 3.12491 9.71373 3.42801V3.99944M7.42801 6.28516H12.5709C13.2021 6.28516 13.7137 6.79683 13.7137 7.42801V12.5709C13.7137 13.2021 13.2021 13.7137 12.5709 13.7137H7.42801C6.79683 13.7137 6.28516 13.2021 6.28516 12.5709V7.42801C6.28516 6.79683 6.79683 6.28516 7.42801 6.28516Z"
          stroke="#F5AC6E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
