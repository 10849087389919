import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../app/hooks/useAuth"

export default function HomePage() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/learn");
    } else {
      navigate("/login");
    }
  }, [isLoggedIn]);
  return <div className="text-price-green text-5xl mt-20">Partners App</div>;
}
