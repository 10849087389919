import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import AssetDashTableItem from "./AssetDashTableItem"

function AssetDashTable({
  selectedItems,
  data,
  identifier,
  text,
  handleDelete,
  handleUpdate,
  enableSelection = false,
}) {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [keys, setKeys] = useState(null);
  const [availableActions, setAvailableActions] = useState({
    editAction: false,
    deleteAction: false,
  });

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/community") {
      setAvailableActions({
        editAction: false,
        deleteAction: true,
      });
    } else if (location.pathname === "/settings/users") {
      setAvailableActions({
        editAction: false,
        deleteAction: false,
      });
    } else if (location.pathname === "/settings/api-keys") {
      setAvailableActions({
        editAction: false,
        deleteAction: true,
      });
    } else {
      setAvailableActions({
        editAction: false,
        deleteAction: false,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (data && data.length > 0) {
      setList(data);
      setKeys(Object.keys(data[0]));
    } else {
      setList([]);
    }
  }, [data]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map((li) => li.member_identifier));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 text-left items-center">
          <span>{text} </span>
          <span className="text-slate-400 font-medium">({data?.length})</span>
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
              <tr>
                {enableSelection && (
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                    <div className="flex items-center">
                      <label className="inline-flex">
                        <span className="sr-only">Select all</span>
                        <input
                          className="form-checkbox"
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </label>
                    </div>
                  </th>
                )}
                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <span className="sr-only">ID</span>
                </th> */}
                {keys?.map((item) => {
                  return (
                    item !== "organization" &&
                    item !== "id" && (
                      <th
                        key={item.id}
                        className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                      >
                        <div className="font-semibold text-left">
                          {item === "member_identifier"
                            ? "Wallet Address"
                            : item === "organization"
                            ? "Member Status"
                            : item.split("_").join(" ")}
                        </div>
                      </th>
                    )
                  );
                })}
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <span className="font-semibold text-left">Actions</span>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200">
              {list?.map((item) => {
                return (
                  <AssetDashTableItem
                    keyItem={item[identifier]}
                    id={item[identifier]}
                    data={item}
                    keys={keys}
                    handleClick={handleClick}
                    availableActions={availableActions}
                    isChecked={isCheck.includes(item[identifier])}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                    enableSelection={enableSelection}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AssetDashTable;
