
import { useEffect, useState } from 'react';
import moment from 'moment';

import { useGames } from '../../../app/hooks/useGames'

export default function GameWebhooksSection({id}) {
  const [page, setPage] = useState(1);
  const { gameUserActionWebhooks, fetchGameUserActionWebhooks } = useGames();
  const ITEMS_PER_PAGE = 30

  useEffect(() => {
    fetchGameUserActionWebhooks(id, page, ITEMS_PER_PAGE);
  }, [id, page]);

  return (
    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">
    <header className="px-5 py-4">
      <h2 className="font-semibold text-slate-800 text-left items-center">
        <span>Webhooks Actions</span>
      </h2>
    </header>
    <div>
      {/* Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          {/* Table header */}
          <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
            <tr>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                ID
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                Created
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                User Id
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                Action
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                Webhook Url
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                Webhook status
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-slate-200">
            {gameUserActionWebhooks?.map((item) => {
              return (
                <tr key={item.id}>
                  <td className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left`}>
                    {item.id}
                  </td>
                  <td className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left`}>
                    {moment(item.created + 'Z').format('MM/DD/YYYY h:mm:ss A')}
                  </td>
                  <td className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left`}>
                    {item.user_id}
                  </td>
                  <td className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left`}>
                    {item.action.split("_").join(" ")}
                  </td>
                  <td className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left`}>
                    {item.webhook_url}
                  </td>
                  <td className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left`}>
                    {item.webhook_status}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between p-4">
        <button 
          onClick={() => setPage(prev => Math.max(prev - 1, 1))}
          disabled={page === 1}
          className={page === 1 ? 'opacity-50 cursor-not-allowed' : ''}
        >
          Previous
        </button>
        <span>Page {page}</span>
        <button 
          onClick={() => setPage(prev => prev + 1)}
          disabled={gameUserActionWebhooks.length < ITEMS_PER_PAGE}
          className={gameUserActionWebhooks.length < ITEMS_PER_PAGE ? 'opacity-50 cursor-not-allowed' : ''}
        >
          Next
        </button>
      </div>
    </div>
  </div>
  )
}
