import { useEffect, useState } from "react";
import { useLearn } from "../../app/hooks/useLearn";
import CourseCard from "../../components/Learn/CourseCard";
import SearchForm from "../../components/TailwindLibrary/actions/SearchForm";
import { ShimmerSimpleGallery } from "react-shimmer-effects-18";
import ModalAction from "../../components/TailwindLibrary/actions/ModalAction";
import ImageUploading from "react-images-uploading";
import { toast } from "react-hot-toast";
import { useAuth } from "../../app/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const courseStatus = ["active", "created", "review", "inactive"];

export default function CoursePage() {
  const {
    courses,
    fetchCourses,
    createCourse,
    isLoading,
    uploadImageAndGetURL,
    courseStatActive,
  } = useLearn();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isButtonClick, setIsButtonClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [uploadBannerImage, setUploadBannerImage] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    setImages([]);
    setImages(imageList || []);
  };
  const onBannerChange = (imageList, addUpdateIndex) => {
    setUploadBannerImage([]);
    setUploadBannerImage(imageList || []);
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  const fileBannerName =
    uploadBannerImage &&
    uploadBannerImage.length > 0 &&
    uploadBannerImage[0]?.file
      ? uploadBannerImage[0]?.file?.name
      : uploadBannerImage[0]
      ? ""
      : "No Selected File";

  useEffect(() => {
    fetchCourses(courseStatActive, page);
  }, [page]);

  const isValid = () => {
    return title !== "" && description !== "" && images.length > 0;
  };

  useEffect(() => {
    if (user && user.organization && !user.organization.permission_learn) {
      navigate("/community");
    }
  }, [user]);

  const handleStatusChange = async (item) => {
    await fetchCourses(item, page);
  };

  const handleAddCourse = async () => {
    setIsButtonClick(true);
    const isVal = isValid();
    if (isVal) {
      const resp =
        images[0]?.file && (await uploadImageAndGetURL(images[0]?.file));
      if (resp && resp.status !== "ok") {
        toast.error(
          resp.error || "Something went wrong while uploading the image. Please try again!"
        );
        setIsButtonClick(false);
        return;
      }
      const bannerResp =
        uploadBannerImage[0]?.file &&
        (await uploadImageAndGetURL(uploadBannerImage[0]?.file));
      if (
        uploadBannerImage &&
        uploadBannerImage.length > 0 &&
        bannerResp &&
        bannerResp.status !== "ok"
      ) {
        toast.error(
          bannerResp.error || "Something went wrong while uploading the image. Please try again!"
        );
        setIsButtonClick(false);
        return;
      }

      const payload = {
        title: title,
        description: description,
        image_url: resp?.data || undefined,
        banner_image_url: bannerResp?.data || undefined,
      };

      await createCourse(payload);

      setTitle("");
      setDescription("");
      setOpen(false);
      setIsButtonClick(false);
    } else {
      toast.error("Please fill out all the fields!");
      setIsButtonClick(false);
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <div className="text-left">
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
              Courses
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Search form */}
            <SearchForm
              placeholder="Search Course"
              handleSearch={handleSearch}
            />
            {/* Filter button */}
            {/* <DropdownFilter align="right" /> */}
            {/* Create campaign button */}
            <button
              className="btn bg-price-green text-black"
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
                analytics.track("create_new_course_button_clicked");
              }}
            >
              <svg
                className="w-4 h-4 fill-current opacity-50 shrink-0"
                viewBox="0 0 16 16"
              >
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
              </svg>
              <span className="hidden xs:block ml-2 font-bold">
                Create Course
              </span>
            </button>
            <ModalAction
              id="add-course-modal"
              modalOpen={open}
              setModalOpen={setOpen}
            >
              {/* Modal header */}
              <div className="mb-4 text-center">
                <div className="text-2xl font-bold text-slate-800">
                  Add Course
                </div>
              </div>
              {/* Modal content */}
              <div className="text-center">
                {/* Submit form */}
                <form
                  className="flex flex-col space-y-4 max-w-sm m-auto"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="grow mr-2">
                    <label htmlFor="title" className="sr-only">
                      Enter the title
                    </label>
                    <input
                      id="title"
                      className="form-input w-full px-2 py-1 text-sm"
                      type="text"
                      value={title}
                      placeholder="Enter title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="grow mr-2">
                    <label htmlFor="description" className="sr-only">
                      Enter the description
                    </label>
                    <input
                      id="description"
                      className="form-input w-full px-2 py-1 text-sm"
                      type="text"
                      value={description}
                      placeholder="Enter description"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg", "jpeg", "svg", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div>
                        <div
                          className="h-36 border-2 border-dashed border-img-upload-border"
                          onClick={() => {
                            onChange();
                            onImageUpload();
                          }}
                          {...dragProps}
                        >
                          <svg
                            className="mx-auto mt-8"
                            width="54"
                            height="39"
                            viewBox="0 0 54 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.8147 38.6977C11.5467 38.4864 8.44151 37.411 5.94254 35.625C3.44358 33.839 1.67861 31.4338 0.899661 28.7527C0.120708 26.0717 0.367599 23.252 1.60508 20.6961C2.84256 18.1403 5.00736 15.9791 7.79037 14.521C8.38253 10.6328 10.6369 7.0595 14.1317 4.46988C17.6264 1.88026 22.122 0.451782 26.7771 0.451782C31.4322 0.451782 35.9278 1.88026 39.4226 4.46988C42.9173 7.0595 45.1717 10.6328 45.7639 14.521C48.5469 15.9791 50.7117 18.1403 51.9492 20.6961C53.1867 23.252 53.4336 26.0717 52.6546 28.7527C51.8757 31.4338 50.1107 33.839 47.6117 35.625C45.1128 37.411 42.0076 38.4864 38.7396 38.6977V38.736H14.8147V38.6977ZM29.1696 22.6182H36.3471L26.7771 12.5446L17.2072 22.6182H24.3847V30.6771H29.1696V22.6182Z"
                              fill="#21CE99"
                            />
                          </svg>
                          <button
                            style={isDragging ? { color: "red" } : null}
                            className="w-full text-center font-bold"
                          >
                            {images && images.length === 0
                              ? "Browse Image to Upload"
                              : "Image Uploaded"}
                          </button>
                          <p className="text-xs">
                            We recommend using a square image under 500 KB
                          </p>
                        </div>
                        <div
                          className="rounded-md mt-4 py-2 px-4"
                          style={{ background: "rgba(33, 206, 153, 0.2)" }}
                        >
                          <div className="flex justify-between items-center">
                            <svg
                              width="14"
                              height="18"
                              viewBox="0 0 14 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.24288 17.0027H11.9136C12.341 17.0027 12.751 16.8329 13.0533 16.5306C13.3555 16.2283 13.5253 15.8184 13.5253 15.3909V5.72023L8.69001 0.884888H2.24288C1.81541 0.884888 1.40545 1.0547 1.10318 1.35697C0.800916 1.65923 0.631104 2.0692 0.631104 2.49667V15.3909C0.631104 15.8184 0.800916 16.2283 1.10318 16.5306C1.40545 16.8329 1.81541 17.0027 2.24288 17.0027ZM7.88412 2.49667L11.9136 6.52612H7.88412V2.49667ZM4.25761 8.1379C4.41641 8.13795 4.57364 8.16928 4.72033 8.2301C4.86703 8.29092 5.0003 8.38004 5.11255 8.49236C5.2248 8.60469 5.31383 8.73802 5.37455 8.88475C5.43527 9.03149 5.4665 9.18874 5.46644 9.34754C5.46639 9.50634 5.43506 9.66357 5.37424 9.81027C5.31342 9.95696 5.22431 10.0902 5.11198 10.2025C4.99966 10.3147 4.86632 10.4038 4.71959 10.4645C4.57286 10.5252 4.4156 10.5564 4.2568 10.5564C3.93609 10.5563 3.62856 10.4288 3.40186 10.2019C3.17516 9.97506 3.04786 9.66745 3.04797 9.34674C3.04808 9.02602 3.17558 8.71849 3.40243 8.49179C3.62928 8.26509 3.9369 8.13779 4.25761 8.1379ZM4.66055 12.1674L5.94756 13.2658L7.88412 9.74968L11.1077 14.585H3.04877L4.66055 12.1674Z"
                                fill="#21CE99"
                              />
                            </svg>
                            <div className="flex space-x-2 items-center">
                              <p className="text-sm">
                                {images && images.length > 0
                                  ? images[0]?.file?.name
                                  : "No Selected File"}
                              </p>
                              <svg
                                onClick={() => onImageRemove(0)}
                                className="cursor-pointer"
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12.1465 1.94373C12.1465 1.34373 11.7465 0.943726 11.1465 0.943726H5.14648C4.54648 0.943726 4.14648 1.34373 4.14648 1.94373V3.94373H0.146484V5.94373H1.14648V15.9437C1.14648 16.5437 1.54648 16.9437 2.14648 16.9437H14.1465C14.7465 16.9437 15.1465 16.5437 15.1465 15.9437V5.94373H16.1465V3.94373H12.1465V1.94373ZM6.14648 2.94373H10.1465V3.94373H6.14648V2.94373ZM13.1465 14.9437V5.94373H3.14648V14.9437H13.1465Z"
                                  fill="#334155"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                  <ImageUploading
                    multiple
                    value={uploadBannerImage}
                    onChange={onBannerChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg", "jpeg", "svg", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div>
                        <div
                          className="h-36 border-2 border-dashed border-img-upload-border"
                          onClick={() => {
                            onBannerChange();
                            onImageUpload();
                          }}
                          {...dragProps}
                        >
                          <svg
                            className="mx-auto mt-8"
                            width="54"
                            height="39"
                            viewBox="0 0 54 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.8147 38.6977C11.5467 38.4864 8.44151 37.411 5.94254 35.625C3.44358 33.839 1.67861 31.4338 0.899661 28.7527C0.120708 26.0717 0.367599 23.252 1.60508 20.6961C2.84256 18.1403 5.00736 15.9791 7.79037 14.521C8.38253 10.6328 10.6369 7.0595 14.1317 4.46988C17.6264 1.88026 22.122 0.451782 26.7771 0.451782C31.4322 0.451782 35.9278 1.88026 39.4226 4.46988C42.9173 7.0595 45.1717 10.6328 45.7639 14.521C48.5469 15.9791 50.7117 18.1403 51.9492 20.6961C53.1867 23.252 53.4336 26.0717 52.6546 28.7527C51.8757 31.4338 50.1107 33.839 47.6117 35.625C45.1128 37.411 42.0076 38.4864 38.7396 38.6977V38.736H14.8147V38.6977ZM29.1696 22.6182H36.3471L26.7771 12.5446L17.2072 22.6182H24.3847V30.6771H29.1696V22.6182Z"
                              fill="#21CE99"
                            />
                          </svg>
                          <button
                            style={isDragging ? { color: "red" } : null}
                            className="w-full text-center font-bold"
                          >
                            {uploadBannerImage && uploadBannerImage.length === 0
                              ? "Browse Banner Image to Upload"
                              : "Banner Image Uploaded"}
                          </button>
                          <p className="text-xs text-center">
                            We recommend using a rectangular image under 500 KB
                          </p>
                        </div>
                        <div
                          className="rounded-md mt-4 py-2 px-4"
                          style={{ background: "rgba(33, 206, 153, 0.2)" }}
                        >
                          <div className="flex justify-between items-center">
                            <svg
                              width="14"
                              height="18"
                              viewBox="0 0 14 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.24288 17.0027H11.9136C12.341 17.0027 12.751 16.8329 13.0533 16.5306C13.3555 16.2283 13.5253 15.8184 13.5253 15.3909V5.72023L8.69001 0.884888H2.24288C1.81541 0.884888 1.40545 1.0547 1.10318 1.35697C0.800916 1.65923 0.631104 2.0692 0.631104 2.49667V15.3909C0.631104 15.8184 0.800916 16.2283 1.10318 16.5306C1.40545 16.8329 1.81541 17.0027 2.24288 17.0027ZM7.88412 2.49667L11.9136 6.52612H7.88412V2.49667ZM4.25761 8.1379C4.41641 8.13795 4.57364 8.16928 4.72033 8.2301C4.86703 8.29092 5.0003 8.38004 5.11255 8.49236C5.2248 8.60469 5.31383 8.73802 5.37455 8.88475C5.43527 9.03149 5.4665 9.18874 5.46644 9.34754C5.46639 9.50634 5.43506 9.66357 5.37424 9.81027C5.31342 9.95696 5.22431 10.0902 5.11198 10.2025C4.99966 10.3147 4.86632 10.4038 4.71959 10.4645C4.57286 10.5252 4.4156 10.5564 4.2568 10.5564C3.93609 10.5563 3.62856 10.4288 3.40186 10.2019C3.17516 9.97506 3.04786 9.66745 3.04797 9.34674C3.04808 9.02602 3.17558 8.71849 3.40243 8.49179C3.62928 8.26509 3.9369 8.13779 4.25761 8.1379ZM4.66055 12.1674L5.94756 13.2658L7.88412 9.74968L11.1077 14.585H3.04877L4.66055 12.1674Z"
                                fill="#21CE99"
                              />
                            </svg>
                            <div className="flex space-x-2 items-center">
                              <p className="text-sm max-w-40 add-overflow-dot">
                                {fileBannerName}
                              </p>
                              <svg
                                onClick={() => onImageRemove(0)}
                                className="cursor-pointer"
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12.1465 1.94373C12.1465 1.34373 11.7465 0.943726 11.1465 0.943726H5.14648C4.54648 0.943726 4.14648 1.34373 4.14648 1.94373V3.94373H0.146484V5.94373H1.14648V15.9437C1.14648 16.5437 1.54648 16.9437 2.14648 16.9437H14.1465C14.7465 16.9437 15.1465 16.5437 15.1465 15.9437V5.94373H16.1465V3.94373H12.1465V1.94373ZM6.14648 2.94373H10.1465V3.94373H6.14648V2.94373ZM13.1465 14.9437V5.94373H3.14648V14.9437H13.1465Z"
                                  fill="#334155"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                  <button
                    className="btn-sm bg-price-green text-white whitespace-nowrap flex space-x-2"
                    onClick={(e) => {
                      e.stopPropagation();

                      if (!isButtonClick) handleAddCourse();
                      else toast.error("Course Creation in progress!");
                      analytics.track("add_course_button_clicked");
                    }}
                  >
                    <p>Add</p>
                    {isButtonClick && (
                      <svg
                        className="animate-spin  h-5 w-5 text-white ml-4 absolute"
                        style={{ left: "54%" }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx={12}
                          cy={12}
                          r={10}
                          stroke="currentColor"
                          strokeWidth={4}
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                  </button>
                </form>
              </div>
            </ModalAction>
          </div>
        </div>

        <div>
          {/* Start */}
          <div className="relative mb-8">
            <div
              className="absolute bottom-0 w-full h-px bg-slate-200"
              aria-hidden="true"
            ></div>
            <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
              {courseStatus.map((courseStat) => {
                return (
                  <li
                    key={courseStat}
                    onClick={() => {
                      setPage(1);
                      handleStatusChange(courseStat);
                      analytics.track("course_status_tab_clicked", {
                        tabName: courseStat,
                      });
                    }}
                    className="cursor-pointer mr-8 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
                  >
                    <p
                      className={`block pb-3 whitespace-nowrap capitalize ${
                        courseStatActive === courseStat
                          ? " text-indigo-500 border-b-2 border-indigo-500 font-bold"
                          : "hover:text-slate-600 font-medium"
                      } `}
                    >
                      {courseStat}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* End */}
        </div>

        {/* Cards */}
        {!isLoading && courses && courses?.length > 0 ? (
          <InfiniteScroll
            dataLength={courses?.length || 100}
            hasMore={true}
            next={handleNext}
          >
            <div className="grid grid-cols-12 gap-6">
              {courses
                .filter((course) => {
                  if (search === "") {
                    return course;
                  } else if (
                    course.title.toLowerCase().includes(search.toLowerCase()) ||
                    course.description
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return course;
                  }
                })
                ?.map((item) => {
                  return <CourseCard key={item.id} id={item.id} data={item} />;
                })}
            </div>
          </InfiniteScroll>
        ) : isLoading ? (
          <ShimmerSimpleGallery card imageHeight={150} />
        ) : (
          <div className="flex items-center text-center justify-center bg-white rounded-xl px-4 md:px-0  py-8">
            <div className="my-20 md:my-28">
              <div className="space-y-4">
                <img
                  src="https://storage.googleapis.com/assetdash-prod-images/web_app/Illustration.svg"
                  className="mx-auto"
                  alt="Course Empty"
                />
                <p className="font-bold text-2xl">
                  Start your first Learn course with just a few clicks
                </p>
                <p className="text-empty-text text-sm">
                  Start growing your community with AssetDash Learn.
                </p>
                <div>
                  <button
                    className="btn bg-price-green text-black mt-8"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(true);
                      analytics.track("create_new_course_button_clicked");
                    }}
                  >
                    <svg
                      className="w-4 h-4 fill-current opacity-50 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2 font-bold">
                      Create Course
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Pagination */}
        {/* <div className="mt-8">
          <PaginationNumeric />
        </div> */}
      </div>
    </div>
  );
}
