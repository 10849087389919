import { axiosAssetDashApi } from "../lib/api";

export const postInviteUsers = async (email) => {
  return await axiosAssetDashApi.post("api_v1/users/invite", {
    email: email,
  });
};

export const postUsersUpdate = async (data) => {
  return await axiosAssetDashApi.post("api_v1/users/update", data);
};
export const getListUsers = async (payload) => {
  return await axiosAssetDashApi.get(
    `api_v1/users/list?page=${payload.page}&status=${payload.status}`
  );
};

export const getReadUser = async (user_id) => {
  return await axiosAssetDashApi.get(`api_v1/users/${user_id}`);
};

export const getUserDetails = async (id) => {
  return await axiosAssetDashApi.get(`api_v1/users/${id}`);
};
