import React from "react";
import { useParams } from "react-router-dom";
import { AsssetDashLogo } from "../../components/AssetDashLogo";
import { ResetPasswordForm } from "../../components/ResetPassword/ResetPasswordForm";

export default function ResetPasswordPage() {
  return (
    <div className="py-22p">
      <div className="mb-20 space-y-2">
        <AsssetDashLogo size="large" forceDark={true} />
        <p className="text-bold text-xl">Partner Portal</p>
      </div>
      <div className=" min-h-screen">
        <ResetPasswordForm />
      </div>
    </div>
  );
}
