import { AsssetDashLogo } from "../../AssetDashLogo";

export default function PreviewSlideWithData({ data }) {
  const { title, text, image_url, partner_image_url } = data;

  return (
    <div
      style={{
        height: "580.31px",
        // backgroundImage:
        //   "url('https://storage.googleapis.com/assetdash-prod-images/web_app/login-onboard-bg.png')",
      }}
      className="bg-cover bg-black py-4 px-4 rounded-sm text-left text-white flex flex-col"
    >
      <AsssetDashLogo />
      <div className="space-y-4 mt-8">
        {/* <p className="text-3xl lineClamp-3">{title}</p> */}
        <p className={`text-center font-bold text-sm min-h-120`}>{text}</p>
        <div>
          {image_url && (
            <img
              src={image_url}
              alt=""
              className="mx-auto w-full h-42 object-contain rounded-12"
            />
          )}
        </div>
        <div>
          {partner_image_url && (
            <img
              src={partner_image_url}
              alt=""
              className="mx-auto w-full h-42 object-scale-down rounded-12"
            />
          )}
        </div>
      </div>
      <div className="mt-auto">
        <button
          className="btn text-black bg-price-green w-full py-2 mt-auto"
          disabled
        >
          Continue
        </button>
      </div>
    </div>
  );
}
