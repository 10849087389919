export const EyeIcon = ({ crossLine, width, height }) => {
  return (
    <>
      {(crossLine && (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.57141 12C2.57141 12 5.99998 5.14282 12 5.14282C18 5.14282 21.4286 12 21.4286 12C21.4286 12 18 18.8571 12 18.8571C5.99998 18.8571 2.57141 12 2.57141 12Z"
            stroke="#21ce99"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.9"
          />
          <path
            d="M12 14.5714C13.4201 14.5714 14.5714 13.4201 14.5714 12C14.5714 10.5798 13.4201 9.42854 12 9.42854C10.5798 9.42854 9.42855 10.5798 9.42855 12C9.42855 13.4201 10.5798 14.5714 12 14.5714Z"
            stroke="#21ce99"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.9"
          />
        </svg>
      )) || (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.8171 13.8172C13.5817 14.0699 13.2978 14.2725 12.9824 14.4131C12.667 14.5536 12.3265 14.6292 11.9812 14.6353C11.6359 14.6414 11.293 14.5779 10.9728 14.4485C10.6526 14.3192 10.3617 14.1267 10.1176 13.8825C9.87339 13.6383 9.68089 13.3475 9.55156 13.0273C9.42223 12.7071 9.35872 12.3642 9.36481 12.0189C9.3709 11.6736 9.44648 11.3331 9.58702 11.0177C9.72756 10.7023 9.9302 10.4184 10.1828 10.183M2.57141 2.57153L21.4286 21.4287M17.0914 17.0915C15.6262 18.2084 13.8421 18.8271 12 18.8572C5.99998 18.8572 2.57141 12.0001 2.57141 12.0001C3.6376 10.0132 5.11638 8.2772 6.90855 6.90868L17.0914 17.0915ZM10.2 5.34868C10.79 5.21057 11.394 5.14154 12 5.14296C18 5.14296 21.4286 12.0001 21.4286 12.0001C20.9083 12.9735 20.2877 13.8899 19.5771 14.7344L10.2 5.34868Z"
            stroke="#21ce99"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.9"
          />
        </svg>
      )}
    </>
  );
};
