import { AsssetDashLogo } from "../AssetDashLogo"
import { SignUpForm } from "./SignupForm"

export default function SignupPage() {
  return (
    <div
      className="py-22p "
      // style={{
      //   backgroundImage:
      //     "url('https://storage.googleapis.com/assetdash-prod-images/web_app/login-onboard-bg.png')",
      // }}
    >
      <div className="mb-20 space-y-2">
        <AsssetDashLogo size="large" forceDark={true} />
        <p className="text-bold text-xl">Partner Portal</p>
      </div>
      <div className="min-h-screen">
        <SignUpForm />
      </div>
    </div>
  );
}
