import { useEffect, useState } from "react"
import { ShimmerTable } from "react-shimmer-effects-18"
import { useUsers } from "../../app/hooks/useUsers"
import AssetDashTable from "../Table/AssetDashTable"
import PaginationClassic from "../TailwindLibrary/PaginationClassic"
import ModalAction from "../TailwindLibrary/actions/ModalAction"
import UserUpdateModal from "./Modal/UserUpdateModal"

const usersStatus = ["active", "invited", "inactive"];
function UsersPanel() {
  const {
    partnerUsers,
    fetchListOfUsers,
    updateUser,
    inviteUsers,
    isLoading,
    isActionLoading,
    usersStatActive,
  } = useUsers();
  const [selectedItems, setSelectedItems] = useState([]);
  const [rowDetail, setRowDetail] = useState(null);
  const [open, setOpen] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [email, setEmail] = useState("");

  const [page, setPage] = useState(1);

  const isNextPage = 100 * page <= partnerUsers?.length;

  const handleNextPage = () => {
    if (!isLoading && isNextPage) {
      setPage(page + 1);
      analytics.track("users_next_button_clicked");
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      analytics.track("users_previous_button_clicked");
    }
  };

  const handleUpdateUser = async (em, da) => {
    await updateUser({
      user_id: rowDetail.id,
      email: em,
      access_level: da,
    });
    setOpen(false);
    setPage(1);
  };

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handleStatusChange = async (item) => {
    await fetchListOfUsers(page, item);
  };

  useEffect(() => {
    if (page && usersStatActive) fetchListOfUsers(page, usersStatActive);
  }, [page]);

  const handleAddUser = async () => {
    if (!isActionLoading) {
      analytics.track("users_add_user_submitted", {
        email,
      });
      await inviteUsers(email);
      fetchListOfUsers(1, usersStatActive);
      setEmail("");
      setPage(1);
      setOpenInvite(false);
    }
  };

  const handleUpdate = async (identifier) => {
    const resp = partnerUsers.find(
      (partnerUser) => partnerUser.id === identifier
    );
    if (resp) {
      setRowDetail(resp);
      setOpen(true);
    }
  };

  const handleDelete = async (identifier) => {
    console.log("====================================");
    console.log("delete me", identifier);
    console.log("====================================");
  };

  return (
    <div className="grow text-left">
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="sm:flex sm:justify-end sm:items-center mb-8">
          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Delete button */}
            {/* <DeleteButton selectedItems={selectedItems} /> */}

            {/* Add customer button */}
            <button
              className="btn bg-price-green text-black"
              aria-controls="add-user-modal"
              onClick={(e) => {
                e.stopPropagation();
                setOpenInvite(true);
                analytics.track("users_add_user_button_clicked");
              }}
            >
              <svg
                className="w-4 h-4 fill-current opacity-50 shrink-0"
                viewBox="0 0 16 16"
              >
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
              </svg>
              <span className="hidden xs:block ml-2">Add User</span>
            </button>
            <ModalAction
              id="add-user-modal"
              modalOpen={openInvite}
              setModalOpen={setOpenInvite}
            >
              {/* Modal header */}
              <div className="mb-2 text-center">
                <div className="text-lg font-semibold text-slate-800">
                  Add User
                </div>
              </div>
              {/* Modal content */}
              <div className="text-center">
                {/* Submit form */}
                <form
                  className="flex max-w-sm m-auto"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    handleAddUser();
                  }}
                >
                  <div className="grow mr-2">
                    <label htmlFor="subscribe-form" className="sr-only">
                      Leave your Email
                    </label>
                    <input
                      id="subscribe-form"
                      className="form-input w-full px-2 py-1"
                      type="email"
                      value={email}
                      placeholder="Enter the email address!"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn-sm bg-price-green text-white whitespace-nowrap"
                  >
                    Invite
                  </button>
                </form>
                {/* <div className="text-xs text-slate-500 italic mt-3">
                I respect your privacy. No spam. Unsubscribe at any time!
              </div> */}
              </div>
            </ModalAction>
          </div>
        </div>

        <div>
          {/* Start */}
          <div className="relative mb-8">
            <div
              className="absolute bottom-0 w-full h-px bg-slate-200"
              aria-hidden="true"
            ></div>
            <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
              {usersStatus.map((userStatus) => {
                return (
                  <li
                    key={userStatus}
                    onClick={() => {
                      setPage(1);
                      handleStatusChange(userStatus);
                      analytics.track("user_status_tab_clicked", {
                        tabName: userStatus,
                      });
                    }}
                    className="cursor-pointer mr-8 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
                  >
                    <p
                      className={`block pb-3 whitespace-nowrap capitalize ${
                        usersStatActive === userStatus
                          ? " text-indigo-500 border-b-2 border-indigo-500 font-bold"
                          : "hover:text-slate-600 font-medium"
                      } `}
                    >
                      {userStatus}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* End */}
        </div>

        {/* Table */}
        {partnerUsers ? (
          <div>
            <AssetDashTable
              selectedItems={handleSelectedItems}
              data={partnerUsers}
              identifier="id"
              text={"Users"}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
            />
            <div className="mt-8">
              <PaginationClassic
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
                page={page}
                isNextPage={isNextPage}
                data={partnerUsers}
              />
            </div>
          </div>
        ) : (
          <div className="bg-white">
            <ShimmerTable row={7} col={5} />
          </div>
        )}

        {rowDetail && open && (
          <UserUpdateModal
            open={open}
            setOpen={setOpen}
            data={rowDetail}
            handleUpdate={handleUpdateUser}
          />
        )}

        {/* Pagination */}
        {/* <div className="mt-8">
              <PaginationClassic />
            </div> */}
      </div>
    </div>
  );
}

export default UsersPanel;
