export const CheckIcon = ({
  width = "12",
  height = "10",
  fill = "rgb(33 206 153)",
  margin = "0",
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${margin !== "0" ? "m-2" : ""}`}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M10.3051 0.78706L4.01213 7.08006L1.71913 4.78706C1.32675 4.40809 0.703041 4.41351 0.317308 4.79924C-0.0684263 5.18497 -0.0738461 5.80868 0.305126 6.20106L3.30513 9.20106C3.69563 9.59144 4.32863 9.59144 4.71913 9.20106L11.7191 2.20106C12.0981 1.80868 12.0927 1.18497 11.7069 0.799241C11.3212 0.413507 10.6975 0.408087 10.3051 0.78706Z"
        fill={fill}
      />
    </svg>
  );
};
