import { axiosAssetDashApi } from "../lib/api";

export const getUserProfile = async () => {
  return await axiosAssetDashApi.get("api_v1/users/me");
};

export const getAllCurrencies = async () => {
  return await axiosAssetDashApi.get("api_v1/users/currency");
};

export const postUserCurrency = async (formValue: any) => {
  return await axiosAssetDashApi.patch(
    `/api_v1/users/change_currency?currency_id=${formValue.currency_id}`
  );
};

export const postAuthSignup = async (formValues: any) => {
  return await axiosAssetDashApi.post("api_v1/auth/signup", formValues);
};

export const postLoginUser = async (email: string, password: string) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append("username", email);
  urlencoded.append("password", password);

  let loginURL = "api_v1/auth/login";

  return await axiosAssetDashApi.post(loginURL, urlencoded, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const postApplyAuth = async (payload: any) => {
  return await axiosAssetDashApi.post("api_v1/auth/apply", payload);
};

export const putUserSettings = async (formValues: any) => {
  return await axiosAssetDashApi.put("api_v1/users/me", formValues);
};

// TODO signin v2 + apple
// Do we still generate the link from the same API? Isn't private key linked to domain?
export const getGoogleLogin = async () => {
  return await axiosAssetDashApi.get("auth/google");
};

export const postOAuthLogin = async (
  token: string,
  platform: string,
  walletData: any
) => {
  let payload = {};
  if (walletData) {
    const { walletAddress, walletType, partnerId } = walletData || {};
    payload = {
      token,
      backend: platform,
      platform_name: "web",
      signup_wallet_type: walletType || undefined,
      signup_wallet_address: walletAddress || undefined,
      signup_source:
        walletAddress && partnerId !== "deals"
          ? "web_partner"
          : walletAddress && partnerId === "deals"
          ? "web_deals"
          : undefined,
      signup_partner_id:
        partnerId && partnerId !== "deals" ? partnerId : undefined,
    };
  } else {
    payload = {
      token,
      backend: platform,
      platform_name: "web",
    };
  }
  return await axiosAssetDashApi.post("api_v1/auth/login", payload);
};

export const postForgotPassword = async (email: string) => {
  return await axiosAssetDashApi.post("api_v1/auth/forgot-password", { email });
};

export const getUserMeDetails = async () => {
  return await axiosAssetDashApi.get("api_v1/users/me");
};

export const postResetPassword = async (request: string) => {
  return await axiosAssetDashApi.post("api_v1/auth/reset-password", request);
};

export const postLogout = async () => {
  const token = localStorage.getItem("token");
  return await axiosAssetDashApi.post("api_v1/auth/logout", { token });
};

export const deleteAccount = async () => {
  return await axiosAssetDashApi.delete("api_v1/users/delete_own_account", {
    data: { reason: "none - Web" },
  });
};
