import { useEffect, useState } from "react";
import { useUsers } from "../../app/hooks/useUsers";
import DeleteButton from "../../components/TailwindLibrary/actions/DeleteButton";
import UserUpdateModal from "../../components/Users/Modal/UserUpdateModal";
import ModalAction from "../../components/TailwindLibrary/actions/ModalAction";
import AssetDashTable from "../../components/Table/AssetDashTable";
import UsersPanel from "../../components/Users/UsersPanel";
import SettingsSidebar from "../../components/TailwindLibrary/settings/SettingsSidebar";

export default function UsersPage() {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Content */}
      <div className="bg-white shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <SettingsSidebar />
          <UsersPanel />
        </div>
      </div>
    </div>
  );
}
