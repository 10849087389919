import { useEffect, useState } from "react"
import { ShimmerTable } from "react-shimmer-effects-18"
import { useAPIKeys } from "../../app/hooks/useAPIKeys"
import AssetDashTable from "../Table/AssetDashTable"
import DeleteButton from "../TailwindLibrary/actions/DeleteButton"

export default function APIKeyPanel() {
  const { createAPIKey, fetchAPIKeyList, apiKeys, isActionLoading, revokeKey } =
    useAPIKeys();
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  useEffect(() => {
    fetchAPIKeyList();
  }, []);

  const handleAddAPIKey = async () => {
    if (!isActionLoading) {
      analytics.track("add_api_key_button_clicked");
      await createAPIKey();
      await fetchAPIKeyList();
    }
  };

  const handleUpdate = async (identifier) => {
    console.log("====================================");
    console.log("update me", identifier);
    console.log("====================================");
  };

  const handleDelete = async (identifier) => {
    await revokeKey(identifier);
    await fetchAPIKeyList();
  };
  return (
    <div className="grow text-left">
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="sm:flex sm:justify-end sm:items-center mb-8">
          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Delete button */}
            <DeleteButton selectedItems={selectedItems} />

            {/* Dropdown */}
            {/* <DateSelect /> */}

            {/* Filter button */}
            {/* <FilterButton align="right" /> */}

            {/* Add customer button */}
            <button
              className="btn bg-price-green text-black"
              aria-controls="add-user-modal"
              onClick={async (e) => {
                e.stopPropagation();
                handleAddAPIKey();
              }}
            >
              <svg
                className="w-4 h-4 fill-current opacity-50 shrink-0"
                viewBox="0 0 16 16"
              >
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
              </svg>
              <span className="hidden xs:block ml-2">Add API Key</span>
            </button>
          </div>
        </div>

        {apiKeys ? (
          <AssetDashTable
            data={apiKeys}
            selectedItems={handleSelectedItems}
            identifier="id"
            text={"API Keys"}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
          />
        ) : (
          <div className="bg-white">
            <ShimmerTable row={7} col={4} />
          </div>
        )}
      </div>
    </div>
  );
}
