export const CrossIcon = ({
  direction,
  color = "#F5AC6E",
  width = "24",
  height = "24",
}) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1412 6.85742L6.85547 17.1431M6.85547 6.85742L17.1412 17.1431"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
