import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../app/hooks/useAuth"
import {
  validateEmailOrReturnError,
  validatePasswordOrReturnError,
} from "../../utils/formatters"
import { PasswordField } from "../Field/PasswordField"
import ModalAction from "../TailwindLibrary/actions/ModalAction"

export const ApplyForm = () => {
  const { apply, isLoading } = useAuth();
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [orgError, setOrgError] = useState("");
  const [open, setOpen] = useState(false);

  const [userFormValues, setUserFormValues] = useState({
    email: "",
    password: "",
    org: "",
  });

  const { email, password, org } = userFormValues;

  useEffect(() => {
    if (emailError) setEmailError("");
  }, [email]);

  useEffect(() => {
    if (passwordError) setPasswordError("");
  }, [password]);

  useEffect(() => {
    if (orgError) setOrgError("");
  }, [org]);

  const handleChange = ({ target: { value, id } }) => {
    setUserFormValues({ ...userFormValues, [id]: value });
  };

  const validateEmail = (val) => {
    const validEmail = validateEmailOrReturnError(val);

    if (validEmail) {
      setEmailError(validEmail);
    } else {
      setEmailError("");
    }
  };

  const validatePassword = (val) => {
    const validPassword = validatePasswordOrReturnError(val, false);
    if (typeof validPassword === "string") {
      setPasswordError(validPassword);
    } else {
      setPasswordError("");
    }
  };

  const validateOrg = (val) => {
    if (org === "") {
      setOrgError("Please enter the Organization name!");
    } else {
      setOrgError("");
    }
  };

  const isValid = () => {
    validateEmail(email);
    validatePassword(password);
    validateOrg(org);
    return passwordError === "" && emailError === "" && orgError === "";
  };

  const handleApply = async (e) => {
    e.preventDefault();
    analytics.track("apply_button_clicked");

    if (isValid()) {
      const payload = {
        name: org,
        email,
        password,
      };
      const resp = await apply(payload);
      if (resp.message === "success") {
        setOpen(true);
        userFormValues.email = "";
        userFormValues.password = "";
        userFormValues.org = "";
      } else {
        toast.error(resp.message);
      }
    }

    userFormValues.email ? setPasswordError("") : setEmailError("Required");
    userFormValues.password
      ? setPasswordError("")
      : setPasswordError("Required");
  };

  return (
    <div className="login-box">
      <div className="justify-center space-y-6">
        <h1 className="text-center text-2xl leading-6 font-bold mb-7">Apply</h1>
        <div className="space-y-2 text-left">
          <div>
            <div className="space-y-4">
              <div className="input-label-text">Name of Organization</div>
              <input
                id="org"
                type="text"
                value={org}
                className="input-box"
                onChange={handleChange}
                onBlur={(e) => validateOrg(e.target.value)}
              />
            </div>

            <div className="text-sm text-primary-red h-5 mt-1">{orgError}</div>
          </div>
          <div>
            <div className="space-y-4">
              <div className="input-label-text">E-mail</div>
              <input
                id="email"
                type="email"
                value={email}
                className="input-box"
                onChange={handleChange}
                onBlur={(e) => validateEmail(e.target.value)}
              />
            </div>

            <div className="text-sm text-primary-red h-5 mt-1">
              {emailError}
            </div>
          </div>
          <div>
            <div className="space-y-4">
              <div className="text-left input-label-text">Password</div>
              <PasswordField
                id="password"
                value={password}
                onChange={handleChange}
                // onBlur={(e) => validatePassword(e.target.value)}
              />
            </div>
            <div className="text-sm text-primary-red h-4 mt-1">
              {passwordError}
            </div>
          </div>
        </div>
        {/* {resultStatus && (
          <div className="text-sm text-primary-red text-center h-5 mt-2">
            {resultStatus}
          </div>
        )} */}
        <div className="flex justify-between items-center">
          <button
            className="flex space-x-2 items-center justify-center solid btn py-4 w-full bg-price-green font-semibold text-sm leading-3.5 text-black"
            aria-controls="announcement-modal"
            onClick={(e) => {
              e.stopPropagation();
              handleApply(e);
            }}
          >
            <p>Apply</p>
            {isLoading && (
              <svg
                className="animate-spin  h-4 w-4 text-white"
                style={{ left: "54%" }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx={12}
                  cy={12}
                  r={10}
                  stroke="currentColor"
                  strokeWidth={4}
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            )}
          </button>
          <ModalAction
            id="announcement-modal"
            modalOpen={open}
            setModalOpen={setOpen}
          >
            {/* Modal header */}
            <div className="mb-2 text-center">
              {/* Icon */}
              <div className="inline-flex mb-2">
                <img
                  src="/images/AnnouncementIcon.svg"
                  width="80"
                  height="80"
                  alt="Announcement"
                />
              </div>
              <div className="text-lg font-semibold text-slate-800">
                Application Submitted!
              </div>
            </div>
            {/* Modal content */}
            <div className="text-center">
              <div className="text-sm mb-5">
                Thank you for applying, someone from our team will reach out
                shortly.
              </div>
              {/* CTAs */}
              <div className="space-y-3">
                <button
                  className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                  onClick={() => navigate("/login")}
                >
                  Back to Login
                </button>
              </div>
            </div>
          </ModalAction>
        </div>
        <div className="flex space-x-2 justify-center items-center mt-7.5  text-center">
          <div className="">Already have an account?</div>
          <div className="font-bold">
            <a
              href="/login"
              onClick={() => analytics.track("back_to_login_clicked")}
            >
              Log In
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
