import React from "react";

function PaginationClassic({
  handleNextPage,
  handlePreviousPage,
  page,
  isNextPage,
  data,
}) {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <nav
        className="mb-4 sm:mb-0 sm:order-1"
        role="navigation"
        aria-label="Navigation"
      >
        <ul className="flex justify-center">
          <li className="ml-3 first:ml-0" onClick={() => handlePreviousPage()}>
            <p
              className={`btn items-center bg-white border-slate-200 ${
                page === 1
                  ? "text-slate-300 cursor-not-allowed"
                  : "hover:border-slate-300 text-indigo-500"
              }`}
              disabled={page === 1}
            >
              &lt; Previous
            </p>
          </li>
          <li className="ml-3 first:ml-0" onClick={() => handleNextPage()}>
            <p
              className={`btn items-center bg-white border-slate-200 ${
                isNextPage
                  ? "hover:border-slate-300 text-indigo-500"
                  : "text-slate-300 cursor-not-allowed"
              }`}
            >
              Next &gt;
            </p>
          </li>
        </ul>
      </nav>
      <div className="text-sm text-slate-500 text-center sm:text-left">
        Showing{" "}
        {data?.length > 0 && (
          <span className="font-medium text-slate-600">1</span>
        )}
        {page > 1 && (
          <span className="font-medium text-slate-600">to {data?.length}</span>
        )}{" "}
        results
      </div>
    </div>
  );
}

export default PaginationClassic;
