import { CheckCircleIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../app/hooks/useAuth";
import { useLearn } from "../../../app/hooks/useLearn";
import ModalBlank from "../../TailwindLibrary/actions/ModalBlank";
import { LearnCTACheckout, LearnCTASubscribeButton } from "./Checkout";

export default function CTABuilder({ isCtaEnabled }) {
  const { fetchUserDetails, user } = useAuth();
  const { fetchCourseDetails, submitCourse } = useLearn();
  const { id } = useParams();

  const learnCourseId = id;
  const [openCtaCheckoutModal, setOpenCtaCheckoutModal] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const { organization } = user || {};

  return (
    <div className="flex m-auto font-inter justify-center items-center rounded-10 text-white my-auto text-center">
      <div>
        <div className="bg-title-bold px-4 md:px-16 py-4 md:py-8">
          <p className="text-2xl md:text-3xl font-bold text-center md:text-left mb-4 md:mb-8 ml-0 md:ml-10">
            Your course is about to go live to ✨
          </p>
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-8 items-center justify-around md:divide-x-1 divide-y-1 md:divide-y-0 divide-divide-col">
            <div className="space-y-2 items-center my-auto">
              <p className="text-11.5 font-bold">100,000+</p>
              <p className="text-left opacity-60">Crypto Wallets</p>
            </div>
            <div className="space-y-2 items-center my-auto pl-0 md:pl-8 py-4 md:py-0">
              <p className="text-11.5 font-bold">$250+ million</p>
              <p className="text-left opacity-60">Verified Investments</p>
            </div>
            <div className="space-y-2 items-center my-auto pl-0 md:pl-8 py-4 md:py-0">
              <p className="text-11.5 font-bold">50,000</p>
              <p className="text-left opacity-60">Unique Investors</p>
            </div>
          </div>
        </div>
        <div className="space-y-8 w-full lg:w-2/3 mx-auto my-8">
          <div className="flex flex-col md:flex-row items-center justify-between text-left  space-x-0 md:space-x-4 lg:space-x-12 space-y-4 md:space-y-0">
            <img
              src="/images/learn-cta-slide-new.png"
              alt="cta "
              className="w-full h-auto object-cover mx-auto"
            />
            <div className="text-black space-y-4">
              <p className="text-3xl font-bold text-title-bold">
                Boost conversions by implementing a Call-To-Action slide.
              </p>
              <div className="space-y-2">
                <p className="font-medium text-xl text-cta-box">
                  Add a call-to-action
                </p>
                <p className="text-cta-box">
                  Premium Learn courses can drive users with a call-to-action
                  slide.
                </p>
              </div>
              <div className="space-y-2">
                <p className="font-medium text-xl text-cta-box">Add a link</p>
                <p className="text-cta-box">
                  Get users to land directly on your website, Discord, or
                  Twitter by adding a link.
                </p>
              </div>
              <div className="space-y-2">
                <p className="font-medium text-xl text-cta-box">Boosted</p>
                <p className="text-cta-box">
                  Premium Learns get{" "}
                  <span className="font-bold text-cta-box">900%</span> more
                  engagement on average and average clickthrough rate on CTA
                  slide is <span className="font-bold text-cta-box">95%.</span>
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="font-medium text-3xl text-cta-box">
              Upgrade to AssetDash Premium <br className="hidden md:block" /> to
              unlock Call-To-Action
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16 text-left">
            <div className="border-1 border-solid border-cta-box p-8 rounded-lg">
              <div className=" divide-y-1 divide-cta-box">
                <div className="pb-4 space-y-4">
                  <p className="text-cta-box font-semibold">Starter</p>
                  <p className="text-lg font-bold text-cta-box">
                    <span className="text-5xl">$0</span>/month
                  </p>
                </div>
                <div className="text-cta-box space-y-4 pt-4">
                  <div className="flex space-x-4 items-center">
                    <CheckCircleIcon className="h-4 w-4 text-very-light-green" />
                    <p>
                      Learn course{" "}
                      <span className="text-cta-box font-bold">without</span>{" "}
                      CTA
                    </p>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <CheckCircleIcon className="h-4 w-4 text-very-light-green" />
                    <p>1 Giveaway Ticket Reward</p>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <CheckCircleIcon className="h-4 w-4 text-very-light-green" />
                    <p>Normal Display Priority</p>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="mt-12 rounded py-4 w-full border-1 border-solid border-cta-box text-cta-box font-bold text-lg"
                  aria-controls="success-modal"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSuccessModalOpen(true);
                  }}
                >
                  Go Starter
                </button>
                <ModalBlank
                  id="success-modal"
                  modalOpen={successModalOpen}
                  setModalOpen={setSuccessModalOpen}
                >
                  <div className="p-5 flex space-x-4">
                    {/* Icon */}
                    <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-emerald-100">
                      <svg
                        className="w-4 h-4 shrink-0 fill-current text-emerald-500"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                      </svg>
                    </div>
                    {/* Content */}
                    <div>
                      {/* Modal header */}
                      <div className="mb-2">
                        <div className="text-lg font-semibold text-slate-800">
                          Are you sure you want to submit this course to review?
                        </div>
                      </div>
                      {/* Modal content */}
                      <div className="text-sm mb-10">
                        <div className="space-y-2">
                          <p className="text-cta-box">
                            You cannot make edits after submission, you will
                            need to remove the course from review in order to
                            make changes.
                          </p>
                        </div>
                      </div>
                      {/* Modal footer */}
                      <div className="flex flex-wrap justify-end space-x-2">
                        <button
                          className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSuccessModalOpen(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                          onClick={async () => {
                            analytics.track(
                              "course_details_submit_button_clicked",
                              {
                                id: id,
                              }
                            );
                            await submitCourse({ course_id: id });
                          }}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </ModalBlank>
              </div>
            </div>
            <div className="border-1 border-solid border-cta-box p-8 rounded-lg bg-title-bold text-white">
              <div className="divide-y-1 divide-cta-box">
                <div className="pb-4 space-y-4">
                  <p className=" font-semibold">Premium</p>
                  <p className="text-lg font-bold ">
                    <span className="text-5xl">$500</span>/month
                  </p>
                </div>
                <div className=" space-y-4 pt-4">
                  <div className="flex space-x-4 items-center">
                    <CheckCircleIcon className="h-4 w-4 text-very-light-green" />
                    <p>
                      Learn course <span className="font-bold">with</span> CTA
                    </p>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <CheckCircleIcon className="h-4 w-4 text-very-light-green" />
                    <p>10 Giveaway Tickets Reward</p>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <CheckCircleIcon className="h-4 w-4 text-very-light-green" />
                    <p>Boosted Display Priority</p>
                  </div>
                </div>
              </div>
              <div>
                <button
                  aria-controls="cta-checkout-modal"
                  className="mt-12 rounded bg-white py-4 w-full border-1 border-solid border-cta-box text-cta-box font-bold text-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenCtaCheckoutModal(true);
                  }}
                >
                  Premium
                </button>
                <ModalBlank
                  id="cta-checkout-modal"
                  modalOpen={openCtaCheckoutModal}
                  setModalOpen={setOpenCtaCheckoutModal}
                >
                  <div className="p-5">
                    {!organization?.permission_billing && !isCtaEnabled && (
                      <LearnCTACheckout
                        learnCourseId={learnCourseId}
                        onSuccess={async () => {
                          await fetchUserDetails();
                          await fetchCourseDetails(learnCourseId);
                        }}
                      />
                    )}
                    {organization?.permission_billing && !isCtaEnabled && (
                      <LearnCTASubscribeButton
                        learnCourseId={learnCourseId}
                        onSuccess={async () => {
                          await fetchUserDetails();
                          await fetchCourseDetails(learnCourseId);
                        }}
                      />
                    )}
                  </div>
                </ModalBlank>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
