import { useLearn } from "../../../app/hooks/useLearn";
import { AsssetDashLogo } from "../../AssetDashLogo";

export default function PreviewCTA() {
  const { ctaTitle, ctaText, ctaUploadImage, ctaUrl } = useLearn();

  const fileName =
    ctaUploadImage && ctaUploadImage.length > 0 && ctaUploadImage[0]?.data_url
      ? ctaUploadImage[0]?.data_url
      : ctaUploadImage[0]
      ? ctaUploadImage[0]
      : "";

  return (
    <div
      style={{
        height: "580.31px",
        // backgroundImage:
        //   "url('https://storage.googleapis.com/assetdash-prod-images/web_app/login-onboard-bg.png')",
      }}
      className="bg-cover bg-black py-4 px-4 rounded-sm text-left text-white flex flex-col"
    >
      <AsssetDashLogo />
      <div className="space-y-4 mt-8">
        {/* <p className="text-3xl lineClamp-3">{ctaTitle}</p> */}
        <p
          className={` lineClamp-10 ${
            ctaText?.length < 220 ? "text-xl" : "text-sm"
          }`}
        >
          {ctaText}
        </p>
        <div>
          {fileName && (
            <img
              src={fileName}
              alt=""
              className="mx-auto w-full object-contain rounded-12 max-h-124"
            />
          )}
        </div>
        <p className="text-xl flex-wrap break-words">{ctaUrl}</p>
      </div>
      <div className="mt-auto">
        <button
          className="btn text-black bg-price-green w-full py-2 mt-auto"
          disabled
        >
          Continue
        </button>
      </div>
    </div>
  );
}
