import { createContext, useContext, useEffect, useState } from "react"
import toast from "react-hot-toast"

import { useLocation, useNavigate } from "react-router-dom"
import {
  getUserMeDetails,
  postApplyAuth,
  postAuthSignup,
  postForgotPassword,
  postLoginUser,
  postResetPassword
} from "../../api/loginapi"

export const AuthContext = createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  // const router = useRouter();
  const router = useNavigate();
  let location = useLocation();
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const { data: session, status } = useSession();
  const [walletData, setWalletData] = useState({});
  const [error, setError] = useState("");
  const [userWalletId, setUserWalletId] = useState("");
  const [currencies, setCurrencies] = useState(null);

  const handleWalletData = (data) => {
    localStorage.setItem("walletData", JSON.stringify(data));
    setWalletData(data);
  };

  useEffect(() => {
    const walletDataLS = JSON.parse(localStorage.getItem("walletData"));
    if (walletDataLS && walletDataLS.walletAddress && walletDataLS.walletType) {
      setWalletData(walletDataLS);
    }
  }, []);

  const isWalletDataEmpty = !(
    walletData &&
    walletData.walletAddress &&
    walletData.walletType
  );

  useEffect(() => {
    if (
      localStorage.getItem("user") &&
      localStorage.getItem("user") !== undefined
    ) {
      !user && setUser(JSON.parse(localStorage.getItem("user")));
    }
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token") !== undefined
    ) {
      setIsLoggedIn(true);
    }
  }, [user]);

  const logout = async () => {
    if (isLoggedIn) {
      // await postLogout();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("walletData");
      setUser(undefined);
      setIsLoggedIn(false);
      setIsLoading(false);

      //   analytics.track("logged_out", { platform: "web" });
      router("/login");
    }
  };

  const setToken = (token) => {
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
  };

  const fetchUserDetails = async () => {
    try {
      const resp = await getUserMeDetails();
      if (resp.status === 200) {
        setUser(resp.data.user);
        localStorage.setItem("user", JSON.stringify(resp.data.user));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const forgotPassword = async (email) => {
    try {
      setIsLoading(true);
      const resp = await postForgotPassword(email);

      if (resp.status === 200) {
        toast.success(resp.data.msg);
        analytics.track("forgot_password_successful");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.detail || "Something went wrong!");
    }
  };

  const resetPassword = async (password, reset_password_token) => {
    try {
      setIsLoading(true);
      const resp = await postResetPassword({ password, reset_password_token });
      if (resp.status === 200) {
        // toast.success(resp.data.msg);
        const data = resp.data;

        localStorage.setItem("token", data.access_token);
        localStorage.setItem("user", JSON.stringify(data.user));
        setUser(data.user);
        analytics.identify(data.user.id, {
          email: data.user.email,
          platform: "web",
        });
        analytics.track("reset_password_successful");
        setIsLoggedIn(true);
        router("/learn");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.detail || "Something went wrong!");
    }
  };

  const apply = async (payload) => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const resp = await postApplyAuth(payload);
        if (resp.status === 200) {
          setIsLoading(false);
          return { data: resp.data, message: "success" };
        }
        setIsLoading(false);
        return { message: "error" };
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        return { message: error?.response?.data?.detail || "error" };
      }
    }
  };

  const login = async (email, password) => {
    if (!isLoading) {
      setIsLoading(true);
      let errorReturn;
      return postLoginUser(email, password)
        .then(async (res) => {
          if (res.status === 200 && res.data.access_token) {
            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("user", JSON.stringify(res.data.user));
            setIsLoggedIn(true);
            // if (hotjar?.initialized())
            //   hotjar.identify("USER_ID", { userProperty: res.data.user.id });
            errorReturn = { status: "ok", message: "success" };
            setUser(res.data.user);
            analytics.identify(res.data.user.id, {
              email: res.data.user.email,
              platform: "web",
            });
            analytics.track("login_successful");
            setIsLoading(false);
            router("/learn");
            return errorReturn;
          } else {
            setIsLoading(false);
            throw new Error("Non 200 response");
          }
        })
        .catch((err) => {
          errorReturn = {
            status: "error",
            message: "Email or password is invalid",
          };
          toast.error(err?.response?.data?.detail);
          setIsLoading(false);

          return errorReturn;
        });
    }
  };

  const signup = async (email, password, id) => {
    if (!isLoading) {
      // const { walletAddress, walletType, walletName } = walletData || {};
      setIsLoading(true);
      return postAuthSignup({
        email,
        password,
        invite_token: id,
      })
        .then(async (res) => {
          if (res.status === 200 && res.data.access_token) {
            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("user", JSON.stringify(res.data.user));
            setUser(res.data.user);
            setIsLoggedIn(true);
            // if (hotjar?.initialized())
            //   hotjar.identify("USER_ID", { userProperty: res.data.user.id });
            analytics.identify(res.data.user.id, {
              email: res.data.user.email,
              platform: "web",
            });
            setIsLoading(false);

            analytics.track("signup_successful");
            return { status: "ok", message: "success", data: res.data };
          } else {
            throw new Error("Non 200 response");
          }
        })
        .catch((err) => {
          setIsLoading(false);

          return {
            status: "error",
            message:
              err?.response?.data?.detail || "Email or password is invalid",
          };
        });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        logout,
        login,
        signup,
        // updateProfile,
        isLoading,
        setIsLoading,
        setToken,
        handleWalletData,
        userWalletId,
        setUserWalletId,
        currencies,
        forgotPassword,
        resetPassword,
        fetchUserDetails,
        apply,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
