import { useLearn } from "../../../app/hooks/useLearn";
import QuestionDetails from "../../../pages/Learn/QuestionDetails";
import moment from "moment";
import ModalBlank from "../../TailwindLibrary/actions/ModalBlank";
import { useEffect, useState } from "react";
import PreviewSlideWithData from "../CourseFill/PreviewSlideWithData";
import ModalAction from "../../TailwindLibrary/actions/ModalAction";
import ImageUploading from "react-images-uploading";
import { Tooltip } from "../../TailwindLibrary/Tooltip";
import { CheckCircleIcon, EyeIcon } from "@heroicons/react/20/solid";
import { toast } from "react-hot-toast";

const courseColor = {
  active: "#21CE99",
  created: "#F5AC6E",
  review: "#A8A8A8",
  inactive: "#E8444D",
};

export default function CourseDetailsSection() {
  const {
    courseDetails,
    deactivateCourse,
    submitCourse,
    uploadImageAndGetURL,
    updateCourse,
  } = useLearn();
  const [open, setOpen] = useState(false);
  const [isButtonClick, setIsButtonClick] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [openEditDetails, setOpenEditDetails] = useState(false);

  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [images, setImages] = useState([]);
  const [uploadBannerImage, setUploadBannerImage] = useState([]);
  const [isImageChange, setIsImageChanged] = useState(false);
  const [isBannerImageChange, setIsBannerImageChanged] = useState(false);

  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    setImages([]);
    setImages(imageList || []);
    setIsImageChanged(true);
  };
  const onBannerChange = (imageList, addUpdateIndex) => {
    setUploadBannerImage([]);
    setUploadBannerImage(imageList || []);
    setIsBannerImageChanged(true);
  };

  const getStatusColor = (status) => {
    return courseColor[status];
  };

  useEffect(() => {
    if (courseDetails && openEditDetails) {
      setEditTitle(courseDetails.title);
      setEditDescription(courseDetails.description);
      setImages(courseDetails.image_url || []);
      setUploadBannerImage(courseDetails.banner_image_url || []);
    }
  }, [courseDetails, openEditDetails]);

  const statusColor = (status) => {
    switch (status) {
      case "active":
        return "bg-emerald-100 text-emerald-600  px-4 py-1 rounded-full inline-flex capitalize";
      case "created":
        return "bg-amber-100 text-amber-600 px-4 py-1 rounded-full inline-flex capitalize";
      case "inactive":
        return "bg-rose-100 text-rose-500 px-4 py-1 rounded-full inline-flex capitalize";
      default:
        return "bg-slate-100 text-slate-500 px-4 py-1 rounded-full inline-flex capitalize";
    }
  };

  const fileBannerName =
    uploadBannerImage &&
    uploadBannerImage.length > 0 &&
    uploadBannerImage[0]?.file
      ? uploadBannerImage[0]?.file?.name
      : uploadBannerImage[0]
      ? ""
      : "No Selected File";

  const {
    image_url,
    title,
    banner_image_url,
    description,
    questions,
    partner_name,
    created,
    num_completed,
    num_started,
    num_views,
    status,
    steps,
    updated,
    partner_logo_url,
    utilization_limit,
    utilization_unavailable,
  } = courseDetails || "";

  const handleEditCourse = async () => {
    setIsButtonClick(true);
    if (isEditActive) {
      const resp =
        isImageChange &&
        images[0]?.file &&
        (await uploadImageAndGetURL(images[0]?.file));
      if (isImageChange && resp && resp.status !== "ok") {
        toast.error(
          resp.error || "Something went wrong while uploading the image. Please try again!"
        );
        setIsButtonClick(false);
        return;
      }

      const bannerResp =
        isBannerImageChange &&
        uploadBannerImage[0]?.file &&
        (await uploadImageAndGetURL(uploadBannerImage[0]?.file));
      if (
        uploadBannerImage &&
        uploadBannerImage.length > 0 &&
        isBannerImageChange &&
        bannerResp &&
        bannerResp.status !== "ok"
      ) {
        toast.error(
          resp.error || "Something went wrong while uploading the image. Please try again!"
        );
        setIsButtonClick(false);
        return;
      }

      const payload = {
        course_id: courseDetails?.id,
        title: editTitle !== courseDetails?.title ? editTitle : undefined,
        description:
          editDescription !== courseDetails?.description
            ? editDescription
            : undefined,
        image_url: isImageChange ? resp?.data : undefined,
        banner_image_url: isBannerImageChange ? bannerResp?.data : undefined,
      };

      analytics.track("course_details_edit_modal_button_clicked", {
        id: courseDetails?.id,
      });

      await updateCourse(payload);

      setEditTitle("");
      setEditDescription("");
      setOpenEditDetails(false);
      setIsButtonClick(false);
    }
  };

  const isEditActive =
    courseDetails &&
    (courseDetails.title !== editTitle ||
      courseDetails.description !== editDescription ||
      (isImageChange ? true : false) ||
      (isBannerImageChange ? true : false));

  // console.log(isEditActive, "is edit active");

  return (
    <div className="space-y-8">
      <div className="mb-4 sm:mb-0 flex justify-between items-center">
        {(courseDetails?.status === "active" ||
          courseDetails?.status === "review") && (
          <div className="ml-auto">
            <div className="ml-auto">
              <button
                className="btn-sm bg-primary-red py-2 px-4 text-white font-bold w-max"
                aria-controls="danger-modal"
                onClick={(e) => {
                  // await deactivateCourse({ course_id: courseDetails?.id });
                  e.stopPropagation();
                  setOpen(true);
                  analytics.track("course_details_deactivate_button_clicked", {
                    id: courseDetails?.id,
                  });
                }}
              >
                Deactivate
              </button>
            </div>
            <ModalBlank
              id="danger-modal"
              modalOpen={open}
              setModalOpen={setOpen}
            >
              <div className="p-5 flex space-x-4">
                {/* Icon */}
                <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
                  <svg
                    className="w-4 h-4 shrink-0 fill-current text-rose-500"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                  </svg>
                </div>
                {/* Content */}
                <div className="w-full">
                  {/* Modal header */}
                  <div className="mb-2">
                    <div className="text-lg font-semibold text-slate-800">
                      Are you sure you want to deactivate the course?
                    </div>
                  </div>
                  {/* Modal content */}
                  <div className="text-sm mb-10">
                    <div className="space-y-2">
                      <p>
                        Deactivating the course will remove this from visibility
                        for all users. No new users will be able to take this
                        course.
                      </p>
                    </div>
                  </div>
                  {/* Modal footer */}
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                        analytics.track(
                          "course_details_deactivate_cancel_clicked",
                          {
                            id: courseDetails?.id,
                          }
                        );
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
                      onClick={async () => {
                        analytics.track(
                          "course_details_deactivate_success_clicked",
                          {
                            id: courseDetails?.id,
                          }
                        );
                        await deactivateCourse({
                          course_id: courseDetails?.id,
                        });
                      }}
                    >
                      Yes, Deactivate it
                    </button>
                  </div>
                </div>
              </div>
            </ModalBlank>
          </div>
        )}
        {courseDetails?.status !== "active" &&
          courseDetails?.status !== "review" && (
            <div className="ml-auto">
              <button
                className="btn-sm bg-price-green py-2 px-4 text-black font-bold w-max"
                aria-controls="danger-modal"
                onClick={async (e) => {
                  e.stopPropagation();
                  setSuccessModalOpen(true);
                }}
              >
                Submit
              </button>
              <ModalBlank
                id="success-modal"
                modalOpen={successModalOpen}
                setModalOpen={setSuccessModalOpen}
              >
                <div className="p-5 flex space-x-4">
                  {/* Icon */}
                  <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-emerald-100">
                    <svg
                      className="w-4 h-4 shrink-0 fill-current text-emerald-500"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                    </svg>
                  </div>
                  {/* Content */}
                  <div>
                    {/* Modal header */}
                    <div className="mb-2">
                      <div className="text-lg font-semibold text-slate-800">
                        Are you sure you want to submit this course to review?
                      </div>
                    </div>
                    {/* Modal content */}
                    <div className="text-sm mb-10">
                      <div className="space-y-2">
                        <p>
                          You cannot make edits after submission, you will need
                          to remove the course from review in order to make
                          changes.
                        </p>
                      </div>
                    </div>
                    {/* Modal footer */}
                    <div className="flex flex-wrap justify-end space-x-2">
                      <button
                        className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSuccessModalOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={async () => {
                          analytics.track(
                            "course_details_submit_button_clicked",
                            {
                              id: courseDetails?.id,
                            }
                          );
                          await submitCourse({ course_id: courseDetails?.id });
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBlank>
            </div>
          )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
        <div className="space-y-2 bg-white rounded-sm shadow-lg p-5 border border-slate-200">
          <div className="flex justify-between">
            <p className="text-3xl font-bold font-inter">Details</p>

            <div className="">
              <p
                className={`capitalize font-bold px-4 py-2 rounded-full ${statusColor(
                  status
                )}`}
              >
                {status}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className=" flex space-x-2 items-center">
              <img
                src={partner_logo_url}
                className="rounded-full h-12 w-12 object-cover"
              />
              <h1 className="text-md md:text-xl text-slate-800 font-bold capitalize">
                {partner_name}
              </h1>
            </div>
            {(courseDetails?.status === "created" ||
              courseDetails?.status === "inactive") && (
              <button
                className="btn border-slate-200 hover:border-slate-300 text-slate-600"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenEditDetails(true);
                  analytics.track("course_details_edit_button_clicked", {
                    id: courseDetails?.id,
                  });
                }}
              >
                <svg
                  className="w-4 h-4 fill-current text-slate-500 shrink-0"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                </svg>
                <span className="ml-2">Edit Content</span>
              </button>
            )}
            <ModalAction
              id="edit-course-modal"
              modalOpen={openEditDetails}
              setModalOpen={setOpenEditDetails}
            >
              {/* Modal header */}
              <div className="mb-4 text-center">
                <div className="text-2xl font-bold text-slate-800">
                  Edit Course Details
                </div>
              </div>
              {/* Modal content */}
              <div className="text-center">
                {/* Submit form */}
                <form
                  className="flex flex-col space-y-4 max-w-sm m-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="grow mr-2">
                    <label htmlFor="title" className="sr-only">
                      Enter the title
                    </label>
                    <input
                      id="title"
                      className="form-input w-full px-2 py-1 text-sm"
                      type="text"
                      value={editTitle}
                      placeholder="Enter title"
                      onChange={(e) => setEditTitle(e.target.value)}
                    />
                  </div>
                  <div className="grow mr-2">
                    <label htmlFor="description" className="sr-only">
                      Enter the description
                    </label>
                    <input
                      id="description"
                      className="form-input w-full px-2 py-1 text-sm"
                      type="text"
                      value={editDescription}
                      placeholder="Enter description"
                      onChange={(e) => setEditDescription(e.target.value)}
                    />
                  </div>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg", "jpeg", "svg", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div>
                        <div
                          className="h-36 border-2 border-dashed border-img-upload-border"
                          onClick={() => {
                            onChange();
                            onImageUpload();
                          }}
                          {...dragProps}
                        >
                          <svg
                            className="mx-auto mt-8"
                            width="54"
                            height="39"
                            viewBox="0 0 54 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.8147 38.6977C11.5467 38.4864 8.44151 37.411 5.94254 35.625C3.44358 33.839 1.67861 31.4338 0.899661 28.7527C0.120708 26.0717 0.367599 23.252 1.60508 20.6961C2.84256 18.1403 5.00736 15.9791 7.79037 14.521C8.38253 10.6328 10.6369 7.0595 14.1317 4.46988C17.6264 1.88026 22.122 0.451782 26.7771 0.451782C31.4322 0.451782 35.9278 1.88026 39.4226 4.46988C42.9173 7.0595 45.1717 10.6328 45.7639 14.521C48.5469 15.9791 50.7117 18.1403 51.9492 20.6961C53.1867 23.252 53.4336 26.0717 52.6546 28.7527C51.8757 31.4338 50.1107 33.839 47.6117 35.625C45.1128 37.411 42.0076 38.4864 38.7396 38.6977V38.736H14.8147V38.6977ZM29.1696 22.6182H36.3471L26.7771 12.5446L17.2072 22.6182H24.3847V30.6771H29.1696V22.6182Z"
                              fill="#21CE99"
                            />
                          </svg>
                          <button
                            style={isDragging ? { color: "red" } : null}
                            className="w-full text-center font-bold"
                          >
                            {images && images.length === 0
                              ? "Browse Image to Upload"
                              : "Image Uploaded"}
                          </button>
                          <p className="text-xs">
                            We recommend using a square image under 500 KB
                          </p>
                        </div>
                        <div
                          className="rounded-md mt-4 py-2 px-4"
                          style={{ background: "rgba(33, 206, 153, 0.2)" }}
                        >
                          <div className="flex justify-between items-center">
                            <svg
                              width="14"
                              height="18"
                              viewBox="0 0 14 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.24288 17.0027H11.9136C12.341 17.0027 12.751 16.8329 13.0533 16.5306C13.3555 16.2283 13.5253 15.8184 13.5253 15.3909V5.72023L8.69001 0.884888H2.24288C1.81541 0.884888 1.40545 1.0547 1.10318 1.35697C0.800916 1.65923 0.631104 2.0692 0.631104 2.49667V15.3909C0.631104 15.8184 0.800916 16.2283 1.10318 16.5306C1.40545 16.8329 1.81541 17.0027 2.24288 17.0027ZM7.88412 2.49667L11.9136 6.52612H7.88412V2.49667ZM4.25761 8.1379C4.41641 8.13795 4.57364 8.16928 4.72033 8.2301C4.86703 8.29092 5.0003 8.38004 5.11255 8.49236C5.2248 8.60469 5.31383 8.73802 5.37455 8.88475C5.43527 9.03149 5.4665 9.18874 5.46644 9.34754C5.46639 9.50634 5.43506 9.66357 5.37424 9.81027C5.31342 9.95696 5.22431 10.0902 5.11198 10.2025C4.99966 10.3147 4.86632 10.4038 4.71959 10.4645C4.57286 10.5252 4.4156 10.5564 4.2568 10.5564C3.93609 10.5563 3.62856 10.4288 3.40186 10.2019C3.17516 9.97506 3.04786 9.66745 3.04797 9.34674C3.04808 9.02602 3.17558 8.71849 3.40243 8.49179C3.62928 8.26509 3.9369 8.13779 4.25761 8.1379ZM4.66055 12.1674L5.94756 13.2658L7.88412 9.74968L11.1077 14.585H3.04877L4.66055 12.1674Z"
                                fill="#21CE99"
                              />
                            </svg>
                            <div className="flex space-x-2 items-center">
                              <p className="text-sm">
                                {images && images.length > 0
                                  ? images[0]?.file?.name
                                  : "No Selected File"}
                              </p>
                              <svg
                                onClick={() => onImageRemove(0)}
                                className="cursor-pointer"
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12.1465 1.94373C12.1465 1.34373 11.7465 0.943726 11.1465 0.943726H5.14648C4.54648 0.943726 4.14648 1.34373 4.14648 1.94373V3.94373H0.146484V5.94373H1.14648V15.9437C1.14648 16.5437 1.54648 16.9437 2.14648 16.9437H14.1465C14.7465 16.9437 15.1465 16.5437 15.1465 15.9437V5.94373H16.1465V3.94373H12.1465V1.94373ZM6.14648 2.94373H10.1465V3.94373H6.14648V2.94373ZM13.1465 14.9437V5.94373H3.14648V14.9437H13.1465Z"
                                  fill="#334155"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                  <ImageUploading
                    multiple
                    value={uploadBannerImage}
                    onChange={onBannerChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg", "jpeg", "svg", "png"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div>
                        <div
                          className="h-36 border-2 border-dashed border-img-upload-border"
                          onClick={() => {
                            onBannerChange();
                            onImageUpload();
                          }}
                          {...dragProps}
                        >
                          <svg
                            className="mx-auto mt-8"
                            width="54"
                            height="39"
                            viewBox="0 0 54 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.8147 38.6977C11.5467 38.4864 8.44151 37.411 5.94254 35.625C3.44358 33.839 1.67861 31.4338 0.899661 28.7527C0.120708 26.0717 0.367599 23.252 1.60508 20.6961C2.84256 18.1403 5.00736 15.9791 7.79037 14.521C8.38253 10.6328 10.6369 7.0595 14.1317 4.46988C17.6264 1.88026 22.122 0.451782 26.7771 0.451782C31.4322 0.451782 35.9278 1.88026 39.4226 4.46988C42.9173 7.0595 45.1717 10.6328 45.7639 14.521C48.5469 15.9791 50.7117 18.1403 51.9492 20.6961C53.1867 23.252 53.4336 26.0717 52.6546 28.7527C51.8757 31.4338 50.1107 33.839 47.6117 35.625C45.1128 37.411 42.0076 38.4864 38.7396 38.6977V38.736H14.8147V38.6977ZM29.1696 22.6182H36.3471L26.7771 12.5446L17.2072 22.6182H24.3847V30.6771H29.1696V22.6182Z"
                              fill="#21CE99"
                            />
                          </svg>
                          <button
                            style={isDragging ? { color: "red" } : null}
                            className="w-full text-center font-bold"
                          >
                            {uploadBannerImage && uploadBannerImage.length === 0
                              ? "Browse Banner Image to Upload"
                              : "Banner Image Uploaded"}
                          </button>
                          <p className="text-xs text-center">
                            We recommend using a rectangular image under 500 KB
                          </p>
                        </div>
                        <div
                          className="rounded-md mt-4 py-2 px-4"
                          style={{ background: "rgba(33, 206, 153, 0.2)" }}
                        >
                          <div className="flex justify-between items-center">
                            <svg
                              width="14"
                              height="18"
                              viewBox="0 0 14 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.24288 17.0027H11.9136C12.341 17.0027 12.751 16.8329 13.0533 16.5306C13.3555 16.2283 13.5253 15.8184 13.5253 15.3909V5.72023L8.69001 0.884888H2.24288C1.81541 0.884888 1.40545 1.0547 1.10318 1.35697C0.800916 1.65923 0.631104 2.0692 0.631104 2.49667V15.3909C0.631104 15.8184 0.800916 16.2283 1.10318 16.5306C1.40545 16.8329 1.81541 17.0027 2.24288 17.0027ZM7.88412 2.49667L11.9136 6.52612H7.88412V2.49667ZM4.25761 8.1379C4.41641 8.13795 4.57364 8.16928 4.72033 8.2301C4.86703 8.29092 5.0003 8.38004 5.11255 8.49236C5.2248 8.60469 5.31383 8.73802 5.37455 8.88475C5.43527 9.03149 5.4665 9.18874 5.46644 9.34754C5.46639 9.50634 5.43506 9.66357 5.37424 9.81027C5.31342 9.95696 5.22431 10.0902 5.11198 10.2025C4.99966 10.3147 4.86632 10.4038 4.71959 10.4645C4.57286 10.5252 4.4156 10.5564 4.2568 10.5564C3.93609 10.5563 3.62856 10.4288 3.40186 10.2019C3.17516 9.97506 3.04786 9.66745 3.04797 9.34674C3.04808 9.02602 3.17558 8.71849 3.40243 8.49179C3.62928 8.26509 3.9369 8.13779 4.25761 8.1379ZM4.66055 12.1674L5.94756 13.2658L7.88412 9.74968L11.1077 14.585H3.04877L4.66055 12.1674Z"
                                fill="#21CE99"
                              />
                            </svg>
                            <div className="flex space-x-2 items-center">
                              <p className="text-sm max-w-40 add-overflow-dot">
                                {fileBannerName}
                              </p>
                              <svg
                                onClick={() => onImageRemove(0)}
                                className="cursor-pointer"
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12.1465 1.94373C12.1465 1.34373 11.7465 0.943726 11.1465 0.943726H5.14648C4.54648 0.943726 4.14648 1.34373 4.14648 1.94373V3.94373H0.146484V5.94373H1.14648V15.9437C1.14648 16.5437 1.54648 16.9437 2.14648 16.9437H14.1465C14.7465 16.9437 15.1465 16.5437 15.1465 15.9437V5.94373H16.1465V3.94373H12.1465V1.94373ZM6.14648 2.94373H10.1465V3.94373H6.14648V2.94373ZM13.1465 14.9437V5.94373H3.14648V14.9437H13.1465Z"
                                  fill="#334155"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                  <button
                    className={`btn-sm flex space-x-2 whitespace-nowrap ${
                      isEditActive
                        ? "bg-price-green text-white"
                        : "bg-gray-text text-black"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();

                      if (!isButtonClick) handleEditCourse();
                      else toast.error("Course Updation in progress!");
                      analytics.track("add_course_button_clicked");
                    }}
                  >
                    <p>Edit</p>
                    {isButtonClick && (
                      <svg
                        className="animate-spin  h-5 w-5 text-white ml-4 absolute"
                        style={{ left: "54%" }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx={12}
                          cy={12}
                          r={10}
                          stroke="currentColor"
                          strokeWidth={4}
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                  </button>
                </form>
                {/* <div className="text-xs text-slate-500 italic mt-3">
                I respect your privacy. No spam. Unsubscribe at any time!
              </div> */}
              </div>
            </ModalAction>
          </div>
          {title && (
            <div className="text-sm py-2">
              <p className="font-bold text-slate-800 font-inter">Title</p>
              <p>{title}</p>
            </div>
          )}
          {description && (
            <div className="text-sm py-2">
              <p className="font-bold text-slate-800 font-inter">Description</p>
              <p>{description}</p>
            </div>
          )}
          {image_url && (
            <div className="text-sm py-2 space-y-2">
              <p className="font-bold text-slate-800 font-inter">Image</p>
              <img
                src={image_url}
                alt="image url"
                className="rounded-full h-12 w-12 object-cover"
              />
            </div>
          )}
          {banner_image_url && (
            <div className="text-sm py-2 space-y-2">
              <p className="font-bold text-slate-800 font-inter">
                Banner Image
              </p>
              <img
                src={banner_image_url}
                alt="image url"
                className=" h-42 object-scale-down rounded-12 py-4 pr-4 md:py-10"
              />
            </div>
          )}
          <div className="text-sm py-2 space-y-2">
            <p className="font-medium text-slate-800 font-inter">Created</p>
            <p>{moment(created + "z").fromNow()}</p>
          </div>
          <div className="text-sm py-2 space-y-2">
            <p className="font-medium text-slate-800 font-inter">
              Last Updated
            </p>
            <p>{moment(updated + "z").fromNow()}</p>
          </div>
        </div>
        <div className="flex flex-col bg-white rounded-sm h-full shadow-lg p-5 border border-slate-200">
          <div className="space-y-2">
            <div className="flex justify-between">
              <p className="text-3xl font-bold font-inter">Statistics</p>
            </div>
          </div>
          {num_started !== null && num_completed !== null && (
            <div className="flex items-center space-x-2 text-xs justify-around pt-8">
              <div className="flex space-x-2 items-center">
                <EyeIcon width={20} height={20} className="text-black" />
                <p>{num_views} views</p>
              </div>
              <div className="flex space-x-2 items-center">
                <CheckCircleIcon width={20} height={20} />
                <p>
                  {num_completed} / {num_started} completed{" "}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {steps?.length > 0 && (
          <h2 className="text-3xl text-slate-800 font-bold mb-8 font-inter">
            Slides
          </h2>
        )}
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {steps &&
            steps.length > 0 &&
            steps.map((step) => {
              return <PreviewSlideWithData data={step} />;
            })}
        </div>
      </div>
      <div>
        {questions && questions.length > 0 && (
          <QuestionDetails data={questions} />
        )}
      </div>
    </div>
  );
}
