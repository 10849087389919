import { createContext, useContext, useState } from "react"
import { toast } from "react-hot-toast"
import {
  getListUsers,
  getUserDetails,
  postInviteUsers,
  postUsersUpdate,
} from "../../api/users"
import { useAuth } from "./useAuth"

export const UsersContext = createContext({});

export function useUsers() {
  return useContext(UsersContext);
}

export const UsersProvider = ({ children }) => {
  const { logout } = useAuth();
  const [partnerUsers, setPartnerUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [usersStatActive, setUsersStatActive] = useState("active");

  const fetchListOfUsers = async (page, item) => {
    try {
      setIsLoading(true);
      const resp = await getListUsers({ page: page, status: item });
      if (resp.status === 200) {
        setPartnerUsers(resp.data.users);
        if (page === 1) {
          setPartnerUsers(resp.data.users);
        } else {
          setPartnerUsers((prevState) => [...prevState, ...resp.data.users]);
        }
      }
      setUsersStatActive(item);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 401) {
        await logout();
      }
    }
  };

  const inviteUsers = async (email) => {
    try {
      setIsActionLoading(true);
      const resp = await postInviteUsers(email);
      setIsActionLoading(false);
      if (resp.status === 200) {
        toast.success(`User having email as ${email} invited!`);
        return resp.data;
      }
    } catch (error) {
      setIsActionLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.detail);
    }
  };

  const updateUser = async (data) => {
    try {
      setIsActionLoading(true);
      const resp = await postUsersUpdate(data);
      setIsActionLoading(false);
      if (resp.status === 200) {
        toast.success(`User update successful!`);
      }
      return resp.data;
    } catch (error) {
      console.log(error);
      setIsActionLoading(false);
    }
  };

  const fetchUserById = async (id) => {
    try {
      const resp = await getUserDetails(id);

      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {}
  };

  return (
    <UsersContext.Provider
      value={{
        partnerUsers,
        isLoading,
        isActionLoading,
        fetchListOfUsers,
        inviteUsers,

        updateUser,
        usersStatActive,
        setUsersStatActive,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
