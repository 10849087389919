import { useNavigate } from "react-router-dom";

export const Logo = ({
  alternateColors = false,
  forceDark = false,
  size = "default",
  forceWhite = false,
}) => {
  const navigate = useNavigate();

  let textSize;
  if (size === "default") {
    textSize = "text-xl";
  } else if (size === "large") {
    textSize = "text-3xl";
  }

  let textClass = "dark:text-white";
  if (forceDark) {
    textClass = "text-white";
  } else if (forceWhite) {
    textClass = "text-white";
  }

  return (
    <div
      className={`${textSize} leading-logo  cursor-pointer ${textClass} `}
      onClick={() => {
        navigate("/");
      }}
    >
      Asset
      <span
        className={`${!alternateColors ? "text-price-green" : ""} font-bold`}
      >
        Dash
      </span>
    </div>
  );
};
