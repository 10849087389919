import { Navigate } from "react-router-dom"

export const ProtectedRoute = ({ children }) => {
  const resp = localStorage.getItem("token");
  if (!resp) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
