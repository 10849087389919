import { useLearn } from "../../../app/hooks/useLearn";
import { AsssetDashLogo } from "../../AssetDashLogo";

export default function PreviewQuestion() {
  const { question, answerA, answerB, answerC, answerD, selectedAnswer } =
    useLearn();

  const handleChange = (e) => {
    console.log(e);
  };

  return (
    <div
      style={{
        height: "580.31px",
        // backgroundImage:
        //   "url('https://storage.googleapis.com/assetdash-prod-images/web_app/login-onboard-bg.png')",
      }}
      className="bg-cover bg-black py-4 px-4 rounded-sm text-left text-white flex flex-col"
    >
      <div className="text-center">
        <AsssetDashLogo />
      </div>
      <div className="space-y-4 mt-8">
        <p className="font-bold text-2xl">{question}</p>
        <div className="space-y-2">
          <div className="flex items-center space-x-2 text-base max-h-22 text-ellipsis overflow-y-hidden overflow-x-hidden">
            {answerA && (
              <input
                type="radio"
                value="a"
                checked={selectedAnswer === "a"}
                onChange={(e) => handleChange(e)}
                className={`${
                  selectedAnswer === "a" ? "bg-price-green" : "bg-black"
                }`}
              />
            )}
            <p>{answerA}</p>
          </div>
          <div className="flex items-center space-x-2 text-base max-h-22 text-ellipsis overflow-y-hidden overflow-x-hidden">
            {answerB && (
              <input
                type="radio"
                value="b"
                checked={selectedAnswer === "b"}
                onChange={(e) => handleChange(e)}
                className={`${
                  selectedAnswer === "b" ? "bg-price-green" : "bg-black"
                }`}
              />
            )}
            <p>{answerB}</p>
          </div>
          <div className="flex items-center space-x-2 text-base max-h-22 text-ellipsis overflow-y-hidden overflow-x-hidden">
            {answerC && (
              <input
                type="radio"
                value="c"
                checked={selectedAnswer === "c"}
                onChange={(e) => handleChange(e)}
                className={`${
                  selectedAnswer === "c" ? "bg-price-green" : "bg-black"
                }`}
              />
            )}
            <p>{answerC}</p>
          </div>
          <div className="flex items-center space-x-2 text-base max-h-22 text-ellipsis overflow-y-hidden overflow-x-hidden">
            {answerD && (
              <input
                type="radio"
                value="d"
                checked={selectedAnswer === "d"}
                onChange={(e) => handleChange(e)}
                className={`${
                  selectedAnswer === "d" ? "bg-price-green" : "bg-black"
                }`}
              />
            )}
            <p>{answerD}</p>
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <button
          className="btn text-black bg-price-green w-full py-2 mt-auto"
          disabled
        >
          Take Quiz
        </button>
      </div>
    </div>
  );
}
