import { axiosAssetDashApi } from "../lib/api";

export const postAddMembers = async (identifier) => {
  return await axiosAssetDashApi.post("api_v1/members/add", {
    member_identifier: identifier,
  });
};

export const postRemoveMembers = async (identifier) => {
  return await axiosAssetDashApi.post("api_v1/members/remove", {
    member_identifier: identifier,
  });
};

export const postRemoveMultipleMembers = async (members) => {
  return await axiosAssetDashApi.post("api_v1/members/remove_members", {
    member_identifiers: members,
  });
};

export const getListMembers = async (payload) => {
  return await axiosAssetDashApi.get(
    `api_v1/members/list?page=${payload.page}&status=${payload.status}`
  );
};

export const readMember = async (identifier) => {
  return await axiosAssetDashApi.get(`api_v1/members/${identifier}`);
};
