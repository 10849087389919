import { useEffect, useState } from "react"
// import { useSession, signIn, signOut } from "next-auth/react";

import { PasswordField } from "../Field/PasswordField"
// import GoogleLogin from "react-google-login";
import { useAuth } from "../../app/hooks/useAuth"
import {
  validateEmailOrReturnError,
  validatePasswordOrReturnError,
} from "../../utils/formatters"

export const LoginForm = () => {
  const { login, isLoading } = useAuth();
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [userFormValues, setUserFormValues] = useState({
    email: "",
    password: "",
  });

  const { email, password } = userFormValues;

  useEffect(() => {
    if (emailError) setEmailError("");
  }, [email]);

  useEffect(() => {
    if (passwordError) setPasswordError("");
  }, [password]);

  const handleChange = ({ target: { value, id } }) => {
    setUserFormValues({ ...userFormValues, [id]: value });
  };

  const validateEmail = (val) => {
    const validEmail = validateEmailOrReturnError(val);

    if (validEmail) {
      setEmailError(validEmail);
    } else {
      setEmailError("");
    }
  };

  const validatePassword = (val) => {
    const validPassword = validatePasswordOrReturnError(val, false);
    if (typeof validPassword === "string") {
      setPasswordError(validPassword);
    } else {
      setPasswordError("");
    }
  };

  const isValid = () => {
    validateEmail(email);
    validatePassword(password);
    return passwordError === "" && emailError === "";
  };

  const handleWebLogin = async (e) => {
    e.preventDefault();
    analytics.track("login_button_clicked");
    if (isValid()) {
      await login(userFormValues.email, userFormValues.password);
    }

    userFormValues.email ? setPasswordError("") : setEmailError("Required");
    userFormValues.password
      ? setPasswordError("")
      : setPasswordError("Required");
  };

  return (
    <div className="login-box">
      <div className="justify-center space-y-6">
        <h1 className="text-center text-2xl leading-6 font-bold mb-7">Login</h1>
        <div className="space-y-2 text-left">
          <div>
            <div className="space-y-4">
              <div className="input-label-text">E-mail</div>
              <input
                id="email"
                type="email"
                value={email}
                className="input-box"
                onChange={handleChange}
                onBlur={(e) => validateEmail(e.target.value)}
              />
            </div>

            <div className="text-sm text-primary-red h-5 mt-1">
              {emailError}
            </div>
          </div>
          <div>
            <div className="space-y-4">
              <div className="text-left input-label-text">Password</div>
              <PasswordField
                id="password"
                value={password}
                onChange={handleChange}
                // onBlur={(e) => validatePassword(e.target.value)}
              />
            </div>
            <div className="text-sm text-primary-red h-4 mt-1">
              {passwordError}
            </div>
          </div>
        </div>
        {/* {resultStatus && (
          <div className="text-sm text-primary-red text-center h-5 mt-2">
            {resultStatus}
          </div>
        )} */}
        <div className="flex justify-between items-center">
          <a
            href="/forgot-password"
            className="text-sm underline underline-offset-1 text-gray-text"
            onClick={() => analytics.track("forgot_password_clicked")}
          >
            Forgot Password?
          </a>
          <button
            className="flex items-center justify-center solid btn py-4 px-8 bg-price-green font-semibold text-sm leading-3.5 text-black"
            onClick={handleWebLogin}
          >
            <div>Sign In</div>
            {isLoading && (
              <svg
                className="animate-spin  h-5 w-5 text-white ml-4 absolute"
                style={{ left: "54%" }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx={12}
                  cy={12}
                  r={10}
                  stroke="currentColor"
                  strokeWidth={4}
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            )}
          </button>
        </div>
        {/* <div>
          <p className="text-sm underline underline-offset-1 text-gray-text text-left">
            Don't you have an account?{" "}
            <a href="users/invite/a" className="text-blue-600 text-base font-bold">
              Sign Up
            </a>
          </p>
        </div> */}
      </div>
    </div>
  );
};
