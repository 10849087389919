import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"
import Dropdowns from "../../Globals/Dropdowns"
import { CrossIcon } from "../../Icon/CrossIcon"
// import { ThemeContext } from "../../style/theme/ThemeContextProvider";

export default function UserUpdateModal({ open, setOpen, data, handleUpdate }) {
  const [email, setEmail] = useState(null);
  const [status, setStatus] = useState("");
  //   const [theme] = useContext(ThemeContext);
  const background = "bg-primary-gray";
  const buttonBackground = "bg-primary-gray";
  const buttonText = "text-black";
  const textColor = "text-black";
  const selectedColor = "text-primary-dark";
  const rowBg = "bg-primary-gray";

  useEffect(() => {
    if (data) {
      const { email, status } = data;
      setEmail(email);
      setStatus(status);
    }
  }, data);
  const accessLevel = ["admin", "user"];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${background} min-h-100 relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6`}
              >
                <div>
                  <div className="text-center">
                    <span
                      className="flex justify-end cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <CrossIcon />
                    </span>
                    <Dialog.Title
                      as="h3"
                      className={`text-3xl font-bold mb-4 ${textColor}`}
                    >
                      Update User Detail
                    </Dialog.Title>
                    <Dialog.Description
                      as="h4"
                      className={`text-sm mt-2 font-medium leading-6 ${textColor} space-y-4`}
                    >
                      <div className="text-left space-y-2">
                        <p className="text-lg">Enter email address to update</p>
                        <input
                          type="text"
                          value={email}
                          placeholder="Enter new Email Address"
                          className="input-box"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="text-left space-y-2">
                        <p className="text-lg">Select Access Level</p>
                        <Dropdowns
                          text={status}
                          data={accessLevel}
                          setStatus={setStatus}
                        />
                      </div>
                    </Dialog.Description>
                    <button
                      className="bg-price-green py-2 w-full font-bold text-black rounded-xl mt-8"
                      onClick={() => {
                        handleUpdate(email, status);
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
