import React from "react";
import { AsssetDashLogo } from "../../components/AssetDashLogo";
import { ForgotPasswordForm } from "../../components/ForgotPassword/ForgotPasswordForm";

export default function ForgotPasswordPage() {
  return (
    <div className="py-22p">
      <div className="mb-20 space-y-2">
        <AsssetDashLogo size="large" forceDark={true} />
        <p className="text-bold text-xl">Partner Portal</p>
      </div>
      <div className=" min-h-screen">
        <ForgotPasswordForm />
      </div>
    </div>
  );
}
