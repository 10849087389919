import {
  createContext,
  useContext,
  useMemo,
  useState
} from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import {
  getCourseDetails,
  getListCourses,
  postCreateCourse,
  postCreateCourseCTA,
  postCreateCourseQuestion,
  postCreateCourseStep,
  postCreateUpdateCTA,
  postDeactivateCourse,
  postSubmitCourse,
  postUpdateCourse,
  postUpdateCourseQuestion,
  postUpdateCourseStep,
  postUploadImage,
} from "../../api/courseApi"

export const LearnContext = createContext({});

export function useLearn() {
  return useContext(LearnContext);
}

export const LearnProvider = ({ children }) => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState(null);
  const [courseDetails, setCourseDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [stepNumber, setStepNumber] = useState(5);
  const [activeSlideStepNumber, setActiveSlideNumber] = useState(0);
  const [questionsNumber, setQuestionsNumber] = useState(5);
  const [activeQuestionStepNumber, setActiveQuestionNumber] = useState(0);
  const [ctaNumber, setCTANumber] = useState(1);
  const [stepActive, setStepActive] = useState("slide");
  const [courseStatActive, setCourseStatActive] = useState("active");
  const [activeTab, setActiveTab] = useState("details");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [uploadImage, setUploadImage] = useState([]);
  const [ctaUploadImage, setCTAUploadImage] = useState([]);
  const [ctaTitle, setCTATitle] = useState("");
  const [ctaText, setCTAText] = useState("");
  const [ctaUrl, setCtaUrl] = useState("");
  const [selectedCTADropdown, setSelectedCTADropdown] = useState("Url");

  const [question, setQuestion] = useState("");
  const [answerA, setAnswerA] = useState("");
  const [answerB, setAnswerB] = useState("");
  const [answerC, setAnswerC] = useState("");
  const [answerD, setAnswerD] = useState("");
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  useMemo(() => {
    setTitle("");
    setDescription("");
    setUploadImage([]);
    setCTAUploadImage([]);
    setQuestion("");
    setAnswerA("");
    setAnswerB("");
    setAnswerC("");
    setAnswerD("");
    setSelectedAnswer(null);

    if (
      courseDetails &&
      courseDetails.steps?.length > 0 &&
      courseDetails.steps?.length <= stepNumber &&
      stepActive === "slide"
    ) {
      const { steps } = courseDetails || [];

      if (steps.length > 0) {
        const res = steps[activeSlideStepNumber];
        if (res) {
          const { title, text, image_url } = res || {};
          setTitle(title || "");
          setDescription(text || "");
          image_url && setUploadImage([image_url]);
        }
      }
    }
    if (
      courseDetails &&
      courseDetails.questions?.length > 0 &&
      courseDetails.questions?.length <= questionsNumber &&
      stepActive === "question"
    ) {
      const { questions } = courseDetails || [];
      if (questions.length > 0) {
        const res = questions[activeQuestionStepNumber];

        if (res) {
          const {
            question,
            answer_a,
            answer_b,
            answer_c,
            answer_d,
            correct_answer,
          } = res || {};
          setQuestion(question);
          setAnswerA(answer_a);
          setAnswerB(answer_b);
          setAnswerC(answer_c);
          setAnswerD(answer_d);
          setSelectedAnswer(correct_answer);
        }
      }
    }
    if (courseDetails && courseDetails.cta && stepActive === "cta") {
      const { cta } = courseDetails || [];
      const res = cta;

      if (res) {
        const { title, text, image_url, cta_url, cta_course_id, cta_deal_id } =
          res || {};

        setCTATitle(title);
        setCTAText(text);
        setCtaUrl(cta_url || cta_course_id || cta_deal_id);

        if (cta_url) setSelectedCTADropdown("Url");
        else if (cta_course_id) setSelectedCTADropdown("Course ID");
        else if (cta_deal_id) setSelectedCTADropdown("Deal ID");
        else setSelectedCTADropdown("Url");
        image_url && setCTAUploadImage([image_url]);
      }
    }
  }, [
    stepNumber,
    courseDetails,
    activeSlideStepNumber,
    activeQuestionStepNumber,
    stepActive,
  ]);

  const createCourse = async (payload) => {
    try {
      setIsLoading(true);
      const resp = await postCreateCourse(payload);

      if (resp.status === 200) {
        toast.success("Course created successfully");
        navigate(`/learn/${resp.data.course.id}`);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.detail);
    }
  };

  const updateCourse = async (payload) => {
    try {
      setIsLoading(true);
      const resp = await postUpdateCourse(payload);

      if (resp.status === 200) {
        setCourseDetails(resp.data.course);
        toast.success("Course updated successfully");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.detail);
    }
  };

  const fetchCourses = async (courseStatus, page) => {
    try {
      page === 1 && setIsLoading(true);
      const resp = await getListCourses({ status: courseStatus, page: page });

      if (resp.status === 200) {
        if (page === 1) setCourses(resp.data.courses);
        else {
          setCourses((prevState) => [...prevState, ...resp.data.courses]);
        }
      }
      setCourseStatActive(courseStatus);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const fetchCourseDetails = async (id) => {
    try {
      setIsLoading(true);
      const resp = await getCourseDetails(id);
      if (resp.status === 200) {
        setCourseDetails(resp.data.course);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const submitCourse = async (data) => {
    try {
      setIsActionLoading(true);
      const resp = await postSubmitCourse(data);

      if (resp.status === 200) {
        toast.success("Course submitted successfully!");
        setCourseDetails(resp.data.course);
        setActiveTab("details");
      }
      setIsActionLoading(false);
    } catch (error) {
      setIsActionLoading(false);
      console.log(error, "error");
    }
  };

  const createStep = async (formValues) => {
    try {
      setIsActionLoading(true);
      const resp = await postCreateCourseStep(formValues);
      if (resp.status === 200) {
        setCourseDetails(resp.data.course);
        setTitle("");
        setDescription("");
        setUploadImage([]);
        activeSlideStepNumber < 4 &&
          setActiveSlideNumber(activeSlideStepNumber + 1);
        if (activeSlideStepNumber === 4) {
          setStepActive("question");
          localStorage.setItem("stepActive", "question");
        }
      }
      setIsActionLoading(false);
      return resp;
    } catch (error) {
      console.log(error);
      setIsActionLoading(false);
      toast.error(error?.response?.data?.detail || "Something went wrong!");
      return error;
    }
  };

  const createCTA = async (formValues) => {
    try {
      setIsActionLoading(true);
      const resp = await postCreateCourseCTA(formValues);
      if (resp.status === 200) {
        setCourseDetails(resp.data.course);
        setCTATitle("");
        setCTAText("");
        setCTAUploadImage([]);
        setCtaUrl("");
      }
      setIsActionLoading(false);
      return resp;
    } catch (error) {
      console.log(error);
      setIsActionLoading(false);
      toast.error(error?.response?.data?.detail || "Something went wrong!");
      return error;
    }
  };

  const updateCTA = async (formValues) => {
    try {
      setIsActionLoading(true);
      const resp = await postCreateUpdateCTA(formValues);
      if (resp.status === 200) {
        setCourseDetails(resp.data.course);
        setCTATitle("");
        setCTAText("");
        setCTAUploadImage([]);
        setCtaUrl("");
        toast.success("CTA Updated successfully!");
      }
      setIsActionLoading(false);
      return resp;
    } catch (error) {
      console.log(error);
      setIsActionLoading(false);
      toast.error(error?.response?.data?.detail || "Something went wrong!");
      return error;
    }
  };

  const deactivateCourse = async (formValues) => {
    try {
      setIsActionLoading(true);
      const resp = await postDeactivateCourse(formValues);

      if (resp.status === 200) {
        setCourseDetails(resp.data.course);
        toast.success("Course Deactivated successfully");
        setActiveTab("builder");
      }
      setIsActionLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.detail);
      setIsActionLoading(false);
    }
  };

  const updateStep = async (formValues) => {
    try {
      setIsActionLoading(true);
      const resp = await postUpdateCourseStep(formValues);
      if (resp.status === 200) {
        setCourseDetails(resp.data.course);
        // setActiveSlideNumber(activeSlideStepNumber + 1);
        setTitle("");
        setDescription("");
        setUploadImage([]);
        toast.success("Step updated successfully!");
      }
      setIsActionLoading(false);
    } catch (error) {
      console.log(error);
      setIsActionLoading(false);
      toast.error(error?.response?.data?.detail || "Something went wrong!");
      return error;
    }
  };

  const createQuestion = async (formValues) => {
    try {
      setIsActionLoading(true);
      const resp = await postCreateCourseQuestion(formValues);
      if (resp.status === 200) {
        setCourseDetails(resp.data.course);
        setQuestion("");
        setAnswerA("");
        setAnswerB("");
        setAnswerC("");
        setAnswerD("");
        setSelectedAnswer(null);
        activeQuestionStepNumber < 4 &&
          setActiveQuestionNumber(activeQuestionStepNumber + 1);
      }
      setIsActionLoading(false);
      return resp;
    } catch (error) {
      console.log(error);
      setIsActionLoading(false);
      toast.error(error?.response?.data?.detail || "Something went wrong!");
      return error;
    }
  };

  const updateQuestion = async (formValues) => {
    try {
      setIsActionLoading(true);
      const resp = await postUpdateCourseQuestion(formValues);
      if (resp.status === 200) {
        setCourseDetails(resp.data.course);
        setQuestion("");
        setAnswerA("");
        setAnswerB("");
        setAnswerC("");
        setAnswerD("");
        setSelectedAnswer(null);
        toast.success("Question updated successfully!");
        // setActiveQuestionNumber(activeQuestionStepNumber + 1);
      }
      setIsActionLoading(false);
    } catch (error) {
      console.log(error);
      setIsActionLoading(false);
      toast.error(error?.response?.data?.detail || "Something went wrong!");
      return error;
    }
  };

  const uploadImageAndGetURL = async (file) => {
    if (file.size > 500 * 1024) {
      return { status: "error", error: "File size should not exceed 500kb." };
    }
  
    try {
      let formdata = new FormData();
      formdata.append("file", file);
      const resp = await postUploadImage(formdata);

      if (resp.status === 200) {
        return { data: resp.data.image_url, status: "ok" };
      } else {
        return { status: "error" };
      }
    } catch (error) {
      console.log(error, "Error");
      return { status: "error" };
    }
  };

  return (
    <LearnContext.Provider
      value={{
        courses,
        isLoading,
        isActionLoading,
        setIsActionLoading,
        fetchCourses,
        fetchCourseDetails,
        courseDetails,
        setCourseDetails,
        createStep,
        updateStep,
        createCourse,

        stepNumber,
        setStepNumber,
        questionsNumber,
        setQuestionsNumber,
        activeSlideStepNumber,
        setActiveSlideNumber,
        activeQuestionStepNumber,
        setActiveQuestionNumber,
        stepActive,
        setStepActive,

        title,
        setTitle,
        description,
        setDescription,
        uploadImage,
        setUploadImage,
        ctaUploadImage,
        setCTAUploadImage,

        question,
        setQuestion,

        answerA,
        setAnswerA,
        answerB,
        setAnswerB,
        answerC,
        setAnswerC,
        answerD,
        setAnswerD,
        selectedAnswer,
        setSelectedAnswer,
        createQuestion,
        updateQuestion,

        uploadImageAndGetURL,
        submitCourse,
        deactivateCourse,
        updateCourse,
        courseStatActive,
        setCourseStatActive,
        activeTab,
        setActiveTab,
        ctaNumber,
        setCTANumber,
        ctaTitle,
        setCTATitle,
        ctaText,
        setCTAText,
        ctaUrl,
        setCtaUrl,

        createCTA,
        updateCTA,
        selectedCTADropdown,
        setSelectedCTADropdown,
      }}
    >
      {children}
    </LearnContext.Provider>
  );
};
