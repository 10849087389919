import { axiosAssetDashApi } from "../lib/api";

export const postCreateAPIKey = async () => {
  return await axiosAssetDashApi.post("api_v1/keys/create");
};
export const postRevokeAPIKey = async (id) => {
  return await axiosAssetDashApi.post("api_v1/keys/revoke", {
    key_id: id,
  });
};

export const getAPIKeyList = async () => {
  return await axiosAssetDashApi.get("api_v1/keys/list");
};

export const getAPIReadKey = async (key_id) => {
  return await axiosAssetDashApi.get(`api_v1/keys/${key_id}`);
};
