import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"

import { useLearn } from "../../../app/hooks/useLearn"
import { isValidUrl } from "../../../utils/Utils"
import AddCTADetails from "../CourseFill/AddCTADetails"
import AddQuestionDetails from "../CourseFill/AddQuestionsDetails"
import AddSlideDetails from "../CourseFill/AddSlideDetails"
import PreviewCTA from "../CourseFill/PreviewCTA"
import PreviewQuestion from "../CourseFill/PreviewQuestion"
import PreviewSlide from "../CourseFill/PreviewSlide"
import TopNavigation from "../CourseFill/TopNavigation"
import CTABuilder from "./CTABuilder"

export default function Builder({ id }) {
  const {
    createStep,
    updateStep,
    courseDetails,
    isActionLoading,
    setIsActionLoading,
    uploadImageAndGetURL,
    activeSlideStepNumber,
    setActiveSlideNumber,
    activeQuestionStepNumber,
    setActiveQuestionNumber,
    stepActive,
    setStepActive,
    title,
    description,
    uploadImage,

    ctaTitle,
    ctaText,
    ctaUrl,
    ctaUploadImage,
    createCTA,
    updateCTA,

    question,
    answerA,
    answerB,
    answerC,
    answerD,
    selectedAnswer,
    createQuestion,
    updateQuestion,
    selectedCTADropdown,
    fetchCourseDetails,
  } = useLearn();

  const [err, setErr] = useState("");
  const { questions, steps, cta } = courseDetails || [];

  console.log(isActionLoading, "action loading status");
  const isValid = () => {
    if (title === "") {
      setErr("Please enter the title!");
    } else if (uploadImage.length === 0) {
      setErr("Please upload an image!");
    }
    return title && uploadImage.length > 0;
  };

  const isValidQuestion = () => {
    if (question === "") {
      setErr("Please enter the questions!");
    } else if (answerA === "" || answerB === "") {
      setErr("Please set the answer options");
    } else if (!selectedAnswer) {
      setErr("Please select the answer!");
    }

    return (
      question !== "" && answerA !== "" && answerB !== "" && selectedAnswer
    );
  };

  const isValidCTA = () => {
    if (ctaTitle === "") {
      setErr("Please enter the Title!");
    } else if (ctaText === "") {
      setErr("Please set the text");
    } else if (ctaUrl === "") {
      setErr("Please set the URL");
    } else if (ctaUploadImage.length === 0) {
      setErr("Please upload an image!");
    }

    return ctaTitle && ctaText && ctaUrl && ctaUploadImage.length > 0;
  };

  const isStepChanged = () => {
    const currentSlide = steps[activeSlideStepNumber];

    if (currentSlide) {
      return (
        currentSlide.title !== title ||
        currentSlide.text !== description ||
        currentSlide.image_url !== uploadImage[0]
      );
    } else {
      return title || description || (uploadImage && uploadImage.length > 0);
    }
  };
  const isQuestionChanged = () => {
    const currentQuestion = questions[activeQuestionStepNumber];
    if (currentQuestion) {
      return (
        currentQuestion.question !== question ||
        currentQuestion.answer_a !== answerA ||
        currentQuestion.answer_b !== answerB ||
        currentQuestion.answer_c !== answerC ||
        currentQuestion.answer_d !== answerD ||
        currentQuestion.correct_answer !== selectedAnswer
      );
    } else {
      return (
        question || answerA || answerB || answerC || answerD || selectedAnswer
      );
    }
  };

  const isCtaValid = () => {
    const currentCTA = cta;

    if (currentCTA) {
      const identifier =
        currentCTA.cta_url ||
        currentCTA.cta_course_id ||
        currentCTA.cta_deal_id;
      return (
        currentCTA.title !== ctaTitle ||
        currentCTA.text !== ctaText ||
        identifier !== ctaUrl ||
        currentCTA.image_url !== ctaUploadImage[0]
      );
    } else {
      return ctaTitle && ctaText && ctaUrl && ctaUploadImage.length > 0;
    }
  };

  const handleComplete = async () => {
    if (stepActive === "slide") {
      if (isStepChanged()) {
        if (isValid()) {
          await handleNext();
          analytics.track("course_details_complete_button_click", {
            id: id,
            stepActive,
          });
          setStepActive("question");
          localStorage.setItem("stepActive", "question");
        }
      } else {
        analytics.track("course_details_complete_button_click", {
          id: id,
          stepActive,
        });
        setStepActive("question");
        localStorage.setItem("stepActive", "question");
      }
    }
    if (stepActive === "question") {
      // submit the course
      if (isQuestionChanged()) {
        if (isValidQuestion()) {
          await handleNext();
          analytics.track("course_details_complete_button_click", {
            id: id,
            stepActive,
          });
          // await submitCourse({ course_id: id });
          setStepActive("cta");
          localStorage.setItem("stepActive", "cta");
        }
      } else if (courseDetails?.questions?.length > 0) {
        analytics.track("course_details_complete_button_click", {
          id: id,
          stepActive,
        });
        // await submitCourse({ course_id: id });
        setStepActive("cta");
        localStorage.setItem("stepActive", "cta");
      }
    }
  };

  const handleNext = async () => {
    if (
      activeSlideStepNumber < courseDetails?.steps?.length &&
      stepActive === "slide"
    ) {
      if (isValid() && isStepChanged()) {
        const imageFile = uploadImage[0]?.file;

        const imageUrlResp =
          imageFile && (await uploadImageAndGetURL(imageFile));

        if (imageUrlResp && imageUrlResp?.status !== "ok") {
          toast.error(
            imageUrlResp.error || "Something went wrong while uploading the image. Please try again!"
          );
          return;
        }
        const courseSlideId = courseDetails?.steps[activeSlideStepNumber];

        analytics.track("course_details_update_step_button_click", {
          id: id,
          stepNo: activeSlideStepNumber + 1,
        });

        const payload = {
          course_id: id,
          course_step_id: courseSlideId?.id,
          title: title === courseSlideId.title ? undefined : title,
          text: title === courseSlideId.description ? undefined : description,
          image_url: imageUrlResp?.data || undefined,
          duration: 10,
        };
        await updateStep(payload);
      } else {
        setErr("Please make some changes to update!");
      }
    } else if (
      activeQuestionStepNumber < courseDetails?.questions?.length &&
      stepActive === "question"
    ) {
      if (isValidQuestion() && isQuestionChanged()) {
        const courseQuestionId =
          courseDetails?.questions[activeQuestionStepNumber];

        const payload = {
          course_id: id,
          course_question_id: courseQuestionId?.id,
          question: question,
          answer_a: answerA,
          answer_b: answerB,
          answer_c: answerC,
          answer_d: answerD,
          correct_answer: selectedAnswer,
        };

        analytics.track("course_details_update_question_button_click", {
          id: id,
          stepNo: activeQuestionStepNumber + 1,
        });

        await updateQuestion(payload);
      } else {
        setErr("Please make some changes to update!");
      }
    } else if (stepActive === "slide") {
      if (isValid()) {
        const imageFile = uploadImage[0]?.file;

        const imageUrlResp = await uploadImageAndGetURL(imageFile);

        if (imageUrlResp && imageUrlResp.status !== "ok") {
          toast.error(
            imageUrlResp.error || "Something went wrong while uploading the image. Please try again!"
          );
          return;
        }

        const payload = {
          course_id: id,
          title: title,
          text: description,
          image_url: imageUrlResp?.data || undefined,
          duration: 10,
        };

        analytics.track("course_details_create_step_button_click", {
          id: id,
          stepNo: activeSlideStepNumber + 1,
        });
        await createStep(payload);
      }
    } else if (stepActive === "question") {
      if (isValidQuestion()) {
        const payload = {
          course_id: id,
          question: question,
          answer_a: answerA,
          answer_b: answerB,
          answer_c: answerC || undefined,
          answer_d: answerD || undefined,
          correct_answer: selectedAnswer,
        };
        analytics.track("course_details_create_question_button_click", {
          id: id,
          stepNo: activeQuestionStepNumber + 1,
        });
        await createQuestion(payload);
      }
    } else if (stepActive === "cta" && !courseDetails?.cta) {
      if (isValidCTA()) {
        const imageFile = ctaUploadImage[0]?.file;

        const imageUrlResp = await uploadImageAndGetURL(imageFile);
        if (imageUrlResp && imageUrlResp.status !== "ok") {
          toast.error(
            imageUrlResp.error || "Something went wrong while uploading the image. Please try again!"
          );
          return;
        }
        const ctaUrlData = selectedCTADropdown === "Url" ? ctaUrl : "";
        const ctaCourseId = selectedCTADropdown === "Course ID" ? ctaUrl : "";
        const ctaDealId = selectedCTADropdown === "Deal ID" ? ctaUrl : "";
        if (selectedCTADropdown === "Url") {
          const resp = isValidUrl(ctaUrl);
          if (!resp) {
            setErr("Please enter a valid Url");
            return;
          }
        }

        const payload = {
          course_id: id,
          title: ctaTitle,
          text: ctaText,
          image_url: imageUrlResp?.data || undefined,
          cta_url: ctaUrlData,
          cta_course_id: ctaCourseId,
          cta_deal_id: ctaDealId,
        };
        analytics.track("course_details_create_cta_button_click", {
          id: id,
        });
        await createCTA(payload);
      }
    } else if (stepActive === "cta" && courseDetails?.cta) {
      if (isCtaValid()) {
        const imageFile = ctaUploadImage[0]?.file;

        const imageUrlResp =
          imageFile && (await uploadImageAndGetURL(imageFile));

        if (imageUrlResp && imageUrlResp?.status !== "ok") {
          toast.error(
            imageUrlResp.error || "Something went wrong while uploading the image. Please try again!"
          );
          return;
        }

        const courseCTA = courseDetails?.cta;

        analytics.track("course_details_update_cta_button_click", {
          id: id,
        });

        const ctaUrlData = selectedCTADropdown === "Url" ? ctaUrl : "";
        const ctaCourseId = selectedCTADropdown === "Course ID" ? ctaUrl : "";
        const ctaDealId = selectedCTADropdown === "Deal ID" ? ctaUrl : "";
        if (selectedCTADropdown === "Url") {
          const resp = isValidUrl(ctaUrl);
          if (!resp) {
            setErr("Please enter a valid Url");
            return;
          }
        }

        const payload = {
          course_id: id,
          title: courseCTA.title !== ctaTitle ? ctaTitle : undefined,
          text: courseCTA.text !== ctaText ? ctaText : undefined,
          image_url: imageUrlResp?.data || undefined,
          cta_url: ctaUrlData,
          cta_course_id: ctaCourseId,
          cta_deal_id: ctaDealId,
          course_cta_id: courseCTA.id,
        };
        await updateCTA(payload);
      } else {
        setErr("Please make some changes to update!");
      }
    }
  };

  useEffect(() => {
    if (courseDetails) {
      const { steps, questions } = courseDetails;

      setErr("");
      if (steps?.length > 0) {
        if (steps.length === 5) {
          setStepActive("question");
          localStorage.setItem("stepActive", "question");
          setActiveQuestionNumber(
            questions.length === 5 ? 4 : questions.length
          );
        } else {
          setActiveSlideNumber(steps.length === 5 ? 4 : steps.length);
        }
      }
    }
  }, [courseDetails]);

  const { is_cta_enabled } = courseDetails || false;

  useEffect(() => {
    return () => {
      setStepActive("slide");
      localStorage.setItem("stepActive", "slide");
      setActiveSlideNumber(0);
      setActiveQuestionNumber(0);
    };
  }, []);

  return (
    <div className="space-y-2">
      <div className="bg-white pt-2 px-6">
        <TopNavigation />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 ">
        <div
          className={`grid row-span-7 bg-white p-4 rounded-md divide divide-y-1 divide-gray-text h-full ${
            !is_cta_enabled && stepActive === "cta"
              ? "col-span-1 md:col-span-4"
              : "md:col-span-2 lg:col-span-3"
          }`}
        >
          <div className="row-span-6 m-auto overflow-visible scroller">
            {stepActive === "slide" && (
              <AddSlideDetails err={err} setErr={setErr} />
            )}
            {stepActive === "question" && (
              <AddQuestionDetails err={err} setErr={setErr} />
            )}
            {stepActive === "cta" && (
              <>
                <div className="ml-auto">
                  {is_cta_enabled && (
                    <AddCTADetails err={err} setErr={setErr} />
                  )}
                  {!is_cta_enabled && (
                    <CTABuilder isCtaEnabled={is_cta_enabled} />
                  )}
                </div>
              </>
            )}
          </div>
          {stepActive !== "cta" && (
            <div className="flex justify-end pt-4 space-x-4 items-center">
              {stepActive !== "cta" && (
                <button
                  className="border-solid border-1 text-indigo-500 font-bold w-32 lg:w-44 py-2 rounded-md h-max"
                  onClick={async () => {
                    if (!isActionLoading) {
                      setIsActionLoading(true);
                      await handleNext();
                      setIsActionLoading(false);
                    }
                  }}
                >
                  {activeSlideStepNumber < courseDetails?.steps?.length &&
                  stepActive === "slide"
                    ? "Update"
                    : activeQuestionStepNumber <
                        courseDetails?.questions?.length &&
                      stepActive === "question"
                    ? "Update"
                    : "Next"}
                </button>
              )}
              {stepActive !== "cta" && (
                <button
                  className={` text-white w-32 lg:w-44 py-2 rounded-md h-max 
                  ${
                    stepActive === "slide" && courseDetails?.steps.length > 0
                      ? "bg-indigo-500"
                      : stepActive === "question" &&
                        courseDetails?.questions.length > 0
                      ? "bg-indigo-500"
                      : "bg-gray-text"
                  }`}
                  onClick={async () => {
                    if (courseDetails?.steps?.length > 0 && !isActionLoading) {
                      setIsActionLoading(true);
                      await handleComplete();
                      setIsActionLoading(false);
                    }
                  }}
                >
                  {stepActive === "slide"
                    ? "Complete Steps"
                    : "Complete Questions"}
                </button>
              )}

              {stepActive === "cta" && is_cta_enabled && (
                <button
                  className={` text-white w-32 lg:w-44 py-2 rounded-md h-max 
                  ${isValidCTA() ? "bg-indigo-500" : "bg-gray-text"}`}
                  onClick={async () => {
                    if (courseDetails?.steps?.length > 0 && !isActionLoading) {
                      setIsActionLoading(true);
                      await handleNext();
                      setIsActionLoading(false);
                    }
                  }}
                >
                  {courseDetails?.cta ? "Update" : "Add CTA"}
                </button>
              )}
            </div>
          )}
          {stepActive === "cta" && is_cta_enabled && (
            <div className="flex justify-end pt-4 space-x-4 items-center">
              <button
                className={` text-white w-32 lg:w-44 py-2 rounded-md h-max 
                  ${isCtaValid() ? "bg-indigo-500" : "bg-gray-text"}`}
                onClick={async () => {
                  if (courseDetails?.steps?.length > 0 && !isActionLoading) {
                    setIsActionLoading(true);
                    await handleNext();
                    setIsActionLoading(false);
                  }
                }}
              >
                {courseDetails?.cta ? "Update" : "Add CTA"}
              </button>
            </div>
          )}
        </div>
        {stepActive === "slide" && (
          <div className="bg-white p-4 rounded-md md:col-span-2 lg:col-span-1">
            <PreviewSlide />
          </div>
        )}
        {stepActive === "question" && (
          <div className="bg-white p-4 rounded-md">
            <PreviewQuestion />
          </div>
        )}
        {stepActive === "cta" && is_cta_enabled && (
          <div className="bg-white p-4 rounded-md md:col-span-2 lg:col-span-1">
            <PreviewCTA />
          </div>
        )}
      </div>
    </div>
  );
}
