import React from "react";
import BillingPanel from "../../components/Billing/BillingPanel";
import SettingsSidebar from "../../components/TailwindLibrary/settings/SettingsSidebar";

export default function BillingPage() {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Content */}
      <div className="bg-white shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <SettingsSidebar />
          <BillingPanel />
        </div>
      </div>
    </div>
  );
}
