import { CheckCircleIcon, EyeIcon } from "@heroicons/react/20/solid"
import moment from "moment"
import { Link } from "react-router-dom"
import { Tooltip } from "../TailwindLibrary/Tooltip"

const courseColor = {
  active: "#21CE99",
  created: "#F5AC6E",
  review: "#A8A8A8",
  inactive: "#E8444D",
};

function CourseCard(props) {
  const { data } = props || {};

  const typeColor = (type) => {
    switch (type) {
      case "One-Time":
        return "bg-emerald-100 text-emerald-600";
      case "At Risk":
        return "bg-amber-100 text-amber-600";
      case "Off-Track":
        return "bg-rose-100 text-rose-600";
      default:
        return "bg-slate-100 text-slate-500";
    }
  };

  const getStatusColor = (status) => {
    return courseColor[status];
  };

  const { num_completed, num_started, num_views, status } = data || "";

  return (
    <div
      className="col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200"
      onClick={() =>
        analytics.track("course_card_clicked", {
          id: data.id,
        })
      }
    >
      <Link
        className="text-slate-800 hover:text-slate-900 mb-1"
        to={`/learn/${data.id}`}
      >
        <div className="flex flex-col p-5 w-full h-full">
          <header>
            <div className="flex justify-between">
              <img
                src={data.image_url || data.partner_logo_url}
                className="rounded-full h-12 w-12 object-cover"
              />
              <div className="">
                <Tooltip
                  text={status?.charAt(0).toUpperCase() + status?.slice(1)}
                  position="bottom"
                >
                  <p
                    className={`capitalize font-bold w-4 h-4 rounded-full`}
                    style={{ backgroundColor: getStatusColor(status) }}
                  ></p>
                </Tooltip>
              </div>
            </div>
          </header>
          <div className="divide divide-y-1 divide-gray-text flex flex-col h-full justify-between">
            <div className="flex flex-col grow mt-2 mb-4">
              <p className="text-2xl font-bold text-black lineClamp-3 mb-2">
                {data?.title}
              </p>
              <h3 className="text-xl font-medium lineClamp-3 mb-2">
                {data?.description}
              </h3>
              <div className="text-sm mt-auto">
                <p>
                  <span className="font-semibold">Updated:</span>{" "}
                  {moment(data?.updated + "z").fromNow()}
                </p>
              </div>
            </div>
            {num_started !== null && num_completed !== null && (
              <div className="flex items-center space-x-2 text-xs justify-around pt-4">
                <div className="flex space-x-2 items-center">
                  <EyeIcon width={20} height={20} className="text-black" />
                  <p>{num_views} views</p>
                </div>
                <div className="flex space-x-2 items-center">
                  <CheckCircleIcon width={20} height={20} />
                  <p>
                    {num_completed} / {num_started} completed{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CourseCard;
