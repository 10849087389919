import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';

import { useMemo } from 'react';
import { hotjar } from 'react-hotjar';
import './App.css';
import { APIProvider } from './app/hooks/useAPIKeys';
import { AuthProvider } from './app/hooks/useAuth';
import { LearnProvider } from './app/hooks/useLearn';
import { GamesProvider } from './app/hooks/useGames';
import { MembersProvider } from './app/hooks/useMembers';
import { SecretModeProvider } from './app/hooks/useSecretMode';
import { UsersProvider } from './app/hooks/useUsers';
import HomePage from './components/Home/HomePage';
import LoginPage from './components/Login/LoginPage';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Screen } from './components/Screen';
import SignupPage from './components/Signup/SignupPage';
import { UnProtectedRoute } from './components/UnProtectedRoute';
import './css/style.css';
import APIKeysPage from './pages/APIKey/APIKeyPage';
import MyAccountPage from './pages/Account/MyAccountPage';
import ApplyPage from './pages/Apply/ApplyPage';
import BillingPage from './pages/Billing/BillingPage';
import CommunityPage from './pages/Community/CommunityPage';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPasswordPage';
import CourseDetailsPage from './pages/Learn/CourseDetailsPage';
import CoursePage from './pages/Learn/CoursePage';
import GameDetailsPage from './pages/Games/GameDetailsPage';
import GameStoreDetailsPage from './pages/Games/GameStoreDetailsPage';
import GamesPage from './pages/Games/GamesPage';
import PageNotFound from './pages/PageNotFounf/PageNotFound';
import ResetPasswordPage from './pages/ResetPassword/ResetPasswordPage';
import UsersPage from './pages/Users/UsersPage';

let analytics = require('react-segment');

function App() {
  analytics.default.load(process.env.REACT_APP_SEGMENT_ID);
  const hjid = process.env.REACT_APP_HOTJAR_HJID || '3365056';
  const hjsv = process.env.REACT_APP_HOTJAR_HJSV || '6';

  const hotjarInitialize = () => {
    hotjar.initialize(
      // @ts-ignore
      hjid,
      hjsv,
    );
  };

  useMemo(() => {
    return hotjarInitialize();
  }, []);

  // @ts-ignore
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

  return (
    <>
      <AuthProvider>
        <MembersProvider>
          <UsersProvider>
            <LearnProvider>
              <GamesProvider>
                <APIProvider>
                  <Screen>
                    <SecretModeProvider>
                      <Toaster position="top-right" reverseOrder={true} />
                      <div className="App">
                        <Routes>
                          <Route path="/" element={<HomePage />} />
                          <Route
                            path="/login"
                            element={
                              <UnProtectedRoute>
                                <LoginPage />
                              </UnProtectedRoute>
                            }
                          />
                          <Route
                            path="/apply"
                            element={
                              <UnProtectedRoute>
                                <ApplyPage />
                              </UnProtectedRoute>
                            }
                          />
                          <Route
                            path="/forgot-password"
                            element={
                              <UnProtectedRoute>
                                <ForgotPasswordPage />
                              </UnProtectedRoute>
                            }
                          />
                          <Route
                            path="/reset-password/:id"
                            element={
                              <UnProtectedRoute>
                                <ResetPasswordPage />
                              </UnProtectedRoute>
                            }
                          />
                          <Route
                            path="/invite/:id"
                            element={
                              <UnProtectedRoute>
                                <SignupPage />
                              </UnProtectedRoute>
                            }
                          />
                          {/* <Route
                            path="/dashboard"
                            element={
                              <ProtectedRoute>
                                <DashboardPage />
                              </ProtectedRoute>
                            }
                          /> */}
                          <Route
                            path="/learn"
                            element={
                              <ProtectedRoute>
                                <CoursePage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/learn/:id"
                            element={
                              <ProtectedRoute>
                                <CourseDetailsPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/games"
                            element={
                              <ProtectedRoute>
                                <GamesPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/games/:id"
                            element={
                              <ProtectedRoute>
                                <GameDetailsPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/games/game-store/:id"
                            element={
                              <ProtectedRoute>
                                <GameStoreDetailsPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/community"
                            element={
                              <ProtectedRoute>
                                <CommunityPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/settings/account"
                            element={
                              <ProtectedRoute>
                                <MyAccountPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/settings/users"
                            element={
                              <ProtectedRoute>
                                <UsersPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/settings/api-keys"
                            element={
                              <ProtectedRoute>
                                <APIKeysPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/settings/billing"
                            element={
                              <ProtectedRoute>
                                <BillingPage />
                              </ProtectedRoute>
                            }
                          />
                          {/* <Route path="/settings" element={<SettingsPage />} /> */}
                          <Route path="*" element={<PageNotFound />} />
                        </Routes>
                      </div>
                    </SecretModeProvider>
                  </Screen>
                </APIProvider>
              </GamesProvider>
            </LearnProvider>
          </UsersProvider>
        </MembersProvider>
      </AuthProvider>
    </>
  );
}

export default App;
