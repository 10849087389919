import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../app/hooks/useAuth';
import { useGames } from '../../app/hooks/useGames';
import GameStoreDetailsSection from '../../components/Games/GameStoreDetails/GameStoreDetailsSection';
import { ShimmerPostDetails } from 'react-shimmer-effects-18';

const gameStoreDetailsTabs = ['details'];
export default function GameStoreDetailsPage() {
  const { id } = useParams();
  const { isLoading, fetchGameStoreDetails, activeTab, setActiveTab } =
    useGames();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.organization && !user.organization.permission_games) {
      navigate('/community');
    }
  }, [user]);

  useMemo(() => {
    if (id) {
      fetchGameStoreDetails(id);
    }
  }, [id]);

  return (
    <div className="text-left" key={id}>
      {!isLoading ? (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <div>
            {/* Start */}
            <div className="relative mb-4">
              <div
                className="absolute bottom-0 w-full h-px bg-slate-200"
                aria-hidden="true"
              ></div>
              <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
                {gameStoreDetailsTabs.map(gameStoreDetailsTab => {
                  return (
                    <li
                      key={gameStoreDetailsTab}
                      onClick={() => {
                        analytics.track('game_store_details_tab_click', {
                          tabName: gameStoreDetailsTab,
                        });
                        setActiveTab(gameStoreDetailsTab);
                      }}
                      className="cursor-pointer mr-8 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
                    >
                      <p
                        className={`block pb-3 whitespace-nowrap capitalize ${
                          activeTab === gameStoreDetailsTab
                            ? ' text-indigo-500 border-b-2 border-indigo-500'
                            : 'hover:text-slate-600'
                        } `}
                      >
                        {gameStoreDetailsTab}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* End */}
          </div>
          {activeTab === 'details' && <GameStoreDetailsSection />}
        </div>
      ) : (
        <div>
          <ShimmerPostDetails card cta variant="EDITOR" />
        </div>
      )}
    </div>
  );
}
