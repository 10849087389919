import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import {
  getGameStoreDetails,
  postCreateGameStore,
  postUpdateGameStore,
  getListGames,
  getGameDetails,
  postCreateGame,
  postUpdateGame,
  postSubmitGame,
  postSubmitGameStore,
  postDeactivateGame,
  postDeactivateGameStore,
  postUploadImage,
  getGamePasses,
  getGameUserActions,
  getGameUserActionWebhooks,
} from "../../api/gameApi";

export const GamesContext = createContext({});

export function useGames() {
  return useContext(GamesContext);
}

export const GamesProvider = ({ children }) => {
  const navigate = useNavigate();
  const [gameStoreDetails, setGameStoreDetails] = useState(null);
  const [games, setGames] = useState(null);
  const [gameDetails, setGameDetails] = useState(null);
  const [gamePasses, setGamePasses] = useState([]);
  const [gameUserActions, setGameUserActions] = useState([]);
  const [gameUserActionWebhooks, setGameUserActionWebhooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [gameStatActive, setGameStatActive] = useState("active");
  const [activeTab, setActiveTab] = useState("details");

  const createGameStore = async (payload) => {
    try {
      setIsLoading(true);
      const resp = await postCreateGameStore(payload);

      if (resp.status === 200) {
        toast.success("Game store created successfully");
        setGameStoreDetails(resp.data.partner_game_store);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.detail);
    }
  };

  const updateGameStore = async (payload) => {
    try {
      setIsLoading(true);
      const resp = await postUpdateGameStore(payload);

      if (resp.status === 200) {
        setGameStoreDetails(resp.data.partner_game_store);
        toast.success("Game store updated successfully");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.detail);
    }
  };

  const fetchGameStoreDetails = async (id) => {
    try {
      setIsLoading(true);
      const resp = await getGameStoreDetails(id);
      if (resp.status === 200) {
        setGameStoreDetails(resp.data.partner_game_store);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };


  const createGame = async (payload) => {
    try {
      setIsLoading(true);
      const resp = await postCreateGame(payload);

      if (resp.status === 200) {
        toast.success("Game created successfully");
        navigate(`/games/${resp.data.partner_game.id}`);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.detail);
    }
  };

  const updateGame = async (payload) => {
    try {
      setIsLoading(true);
      const resp = await postUpdateGame(payload);

      if (resp.status === 200) {
        setGameDetails(resp.data.partner_game);
        toast.success("Game updated successfully");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.detail);
    }
  };

  const fetchGames = async (gameStatus, page) => {
    try {
      page === 1 && setIsLoading(true);
      const resp = await getListGames({ status: gameStatus, page: page });

      if (resp.status === 200) {
        if (page === 1) setGames(resp.data.partner_games);
        else {
          setGames((prevState) => [...prevState, ...resp.data.partner_games]);
        }
      }
      setGameStatActive(gameStatus);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const fetchGameDetails = async (id) => {
    try {
      setIsLoading(true);
      const resp = await getGameDetails(id);
      if (resp.status === 200) {
        setGameDetails(resp.data.partner_game);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const submitGameStore = async (data) => {
    try {
      setIsActionLoading(true);
      const resp = await postSubmitGameStore(data);

      if (resp.status === 200) {
        toast.success("Game store submitted successfully!");
        setGameStoreDetails(resp.data.partner_game_store);
        setActiveTab("details");
      }
      setIsActionLoading(false);
    } catch (error) {
      setIsActionLoading(false);
      console.log(error, "error");
    }
  };

  const submitGame = async (data) => {
    try {
      setIsActionLoading(true);
      const resp = await postSubmitGame(data);

      if (resp.status === 200) {
        toast.success("Game submitted successfully!");
        setGameDetails(resp.data.partner_game);
        setActiveTab("details");
      }
      setIsActionLoading(false);
    } catch (error) {
      setIsActionLoading(false);
      console.log(error, "error");
    }
  };

  const deactivateGameStore = async (formValues) => {
    try {
      setIsActionLoading(true);
      const resp = await postDeactivateGameStore(formValues);

      if (resp.status === 200) {
        setGameStoreDetails(resp.data.partner_game_store);
        await fetchGames('inactive', 1);
        setGameStatActive("inactive");
        toast.success("Game Store Deactivated successfully");
      }
      setIsActionLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.detail);
      setIsActionLoading(false);
    }
  };

  const deactivateGame = async (formValues) => {
    try {
      setIsActionLoading(true);
      const resp = await postDeactivateGame(formValues);

      if (resp.status === 200) {
        setGameDetails(resp.data.partner_game);
        toast.success("Game Deactivated successfully");
      }
      setIsActionLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.detail);
      setIsActionLoading(false);
    }
  };

  const uploadImageAndGetURL = async (file) => {
    try {
      let formdata = new FormData();
      formdata.append("file", file);
      const resp = await postUploadImage(formdata);

      if (resp.status === 200) {
        return { data: resp.data.image_url, status: "ok" };
      } else {
        return { status: "error" };
      }
    } catch (error) {
      console.log(error, "Error");
      return { status: "error" };
    }
  };

  const fetchGamePasses = async (id, page, limit) => {
    try {
      const resp = await getGamePasses(id, page, limit);
      if (resp.status === 200) {
        setGamePasses(resp.data.partner_game_passes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGameUserActions = async (id, page, limit) => {
    try {
      const resp = await getGameUserActions(id, page, limit);
      if (resp.status === 200) {
        setGameUserActions(resp.data.partner_game_user_actions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGameUserActionWebhooks = async (id, page, limit) => {
    try {
      const resp = await getGameUserActionWebhooks(id, page, limit);
      if (resp.status === 200) {
        setGameUserActionWebhooks(resp.data.partner_game_user_action_webhooks);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <GamesContext.Provider
      value={{
        gameStoreDetails,
        updateGameStore,
        createGameStore,
        fetchGameStoreDetails,
        fetchGamePasses,
        fetchGameUserActions,
        fetchGameUserActionWebhooks,

        games,
        isLoading,
        isActionLoading,
        fetchGames,
        fetchGameDetails,
        gameDetails,
        createGame,
        gamePasses,
        gameUserActions,
        gameUserActionWebhooks,

        setActiveTab,

        uploadImageAndGetURL,
        submitGame,
        deactivateGame,
        submitGameStore,
        deactivateGameStore,
        updateGame,
        gameStatActive,
        activeTab,
      }}
    >
      {children}
    </GamesContext.Provider>
  );
};
