import { axiosAssetDashApi } from "../lib/api";

export const getListCourses = async (payload) => {
  return await axiosAssetDashApi.get(
    `api_v1/learn/courses/list?status=${payload.status}&page=${payload.page}`
  );
};

export const getCourseDetails = async (identifier) => {
  return await axiosAssetDashApi.get(
    `api_v1/learn/course/details?course_id=${identifier}`
  );
};

export const postCreateCourse = async (payload) => {
  return await axiosAssetDashApi.post("api_v1/learn/course/create", payload);
};

export const postUpdateCourse = async (formValues) => {
  // title and image_url and courseId
  return await axiosAssetDashApi.post("api_v1/learn/course/update", formValues);
};

export const postSubmitCourse = async (formValues) => {
  // courseId
  return await axiosAssetDashApi.post("api_v1/learn/course/submit", formValues);
};

export const postDeactivateCourse = async (formValues) => {
  // courseId
  return await axiosAssetDashApi.post(
    "api_v1/learn/course/deactivate",
    formValues
  );
};

export const postCreateCourseStep = async (formValues) => {
  return await axiosAssetDashApi.post(
    `api_v1/learn/course/step/create`,
    formValues
  );
};

export const postCreateCourseCTA = async (formValues) => {
  return await axiosAssetDashApi.post(
    `api_v1/learn/course/cta/create`,
    formValues
  );
};

export const postCreateUpdateCTA = async (formValues) => {
  return await axiosAssetDashApi.post(
    `api_v1/learn/course/cta/update`,
    formValues
  );
};

export const postUpdateCourseStep = async (formValues) => {
  return await axiosAssetDashApi.post(
    `api_v1/learn/course/step/update`,
    formValues
  );
};

export const postCreateCourseQuestion = async (formValues) => {
  return await axiosAssetDashApi.post(
    `api_v1/learn/course/question/create`,
    formValues
  );
};

export const postUpdateCourseQuestion = async (formValues) => {
  return await axiosAssetDashApi.post(
    `api_v1/learn/course/question/update`,
    formValues
  );
};

export const postUploadImage = async (imageDataUrl) => {
  return await axiosAssetDashApi.post(
    "api_v1/learn/upload_image",
    imageDataUrl,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
