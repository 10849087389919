import { createContext, useContext, useState } from "react"
import { toast } from "react-hot-toast"
import {
  getAPIKeyList,
  getAPIReadKey,
  postCreateAPIKey,
  postRevokeAPIKey,
} from "../../api/apiKeys"
import { useAuth } from "./useAuth"

export const APIContext = createContext({});

export function useAPIKeys() {
  return useContext(APIContext);
}

export const APIProvider = ({ children }) => {
  const { logout } = useAuth();
  const [apiKeys, setApiKeys] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  const fetchAPIKeyList = async () => {
    try {
      setIsLoading(true);
      const resp = await getAPIKeyList();
      if (resp.status === 200) {
        setApiKeys(resp.data.keys);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error api key list");
      if (error.response.status === 401) {
        await logout();
      }
    }
  };

  const createAPIKey = async () => {
    try {
      setIsActionLoading(true);
      const resp = await postCreateAPIKey();
      if (resp.status === 200) {
        toast.success("API Key generated successfully");
      }
      setIsActionLoading(false);
    } catch (error) {
      setIsActionLoading(false);
      console.log(error, "error api key create");
    }
  };

  const revokeKey = async (id) => {
    try {
      setIsActionLoading(true);
      const resp = await postRevokeAPIKey(id);
      if (resp.status === 200) {
        toast.success("API Key revoked successfully");
      }
      setIsActionLoading(false);
    } catch (error) {
      setIsActionLoading(false);
      console.log(error, "error api key revoke");
    }
  };

  const readAPIKey = async (id) => {
    try {
      setIsActionLoading(true);
      const resp = await getAPIReadKey(id);
      setIsActionLoading(false);
      return resp.data;
    } catch (error) {
      setIsActionLoading(false);
      console.log(error, "error api key read");
    }
  };

  return (
    <APIContext.Provider
      value={{
        isLoading,
        isActionLoading,

        apiKeys,
        fetchAPIKeyList,
        createAPIKey,
        revokeKey,
        readAPIKey,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};
