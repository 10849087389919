import { toast } from "react-hot-toast"
import { CopyIcon } from "../Icon/CopyIcon"

function AssetDashTableItem(props) {
  const {
    id,
    keyItem,
    data,
    keys,
    handleClick,
    isChecked,
    availableActions,
    handleUpdate,
    handleDelete,
    enableSelection,
  } = props || {};

  const { editAction, deleteAction } = availableActions || false;

  const totalColor = (status) => {
    switch (status) {
      case "active":
        return "text-emerald-500";
      case "invited":
        return "text-amber-500";
      case "inactive":
        return "text-rose-500";
      default:
        return "text-slate-500";
    }
  };

  const statusColor = (status) => {
    switch (status) {
      case "active":
        return "bg-emerald-100 text-emerald-600  px-2.5 py-0.5 rounded-full inline-flex capitalize";
      case "invited":
        return "bg-amber-100 text-amber-600 px-2.5 py-0.5 rounded-full inline-flex capitalize";
      case "inactive":
        return "bg-rose-100 text-rose-500 px-2.5 py-0.5 rounded-full inline-flex capitalize";
      default:
        return "bg-slate-100 text-slate-500 px-2.5 py-0.5 rounded-full inline-flex capitalize";
    }
  };

  return (
    <tr key={keyItem}>
      {enableSelection && (
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input
                id={id}
                className="form-checkbox"
                type="checkbox"
                onChange={handleClick}
                checked={isChecked}
              />
            </label>
          </div>
        </td>
      )}
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center relative">
          <button>
            <svg
              className={`w-4 h-4 shrink-0 fill-current ${
                fav ? "text-amber-500" : "text-slate-300"
              }`}
              viewBox="0 0 16 16"
            >
              <path d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z" />
            </svg>
          </button>
        </div>
      </td> */}
      {keys?.map((keyItem) => {
        return (
          keyItem !== "id" &&
          keyItem !== "organization" && (
            <td
              key={keyItem}
              className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-left ${
                keyItem === "status" || keyItem === "member_status" ? "" : ""
              }`}
            >
              <div
                className={`items-center ${
                  keyItem === "status"
                    ? statusColor(data[keyItem])
                    : keyItem === "member_status"
                    ? "bg-amber-100 text-amber-600 px-2.5 py-0.5 rounded-full inline-flex uppercase"
                    : ""
                }`}
              >
                <div className="font-medium text-slate-800  flex space-x-2 items-center">
                  <p>
                    {keyItem === "organization"
                      ? data[keyItem].member_status
                      : data[keyItem]}
                  </p>
                  {keyItem === "invite_url" && (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(data[keyItem]);
                        toast.success("Invite URL copied");
                        analytics.track("users_copy_invite_url_clicked", {
                          url: data[keyItem],
                        });
                      }}
                    >
                      <CopyIcon />
                    </div>
                  )}
                </div>
              </div>
            </td>
          )
        );
      })}

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="space-x-1">
          {editAction && (
            <button
              className="text-slate-400 hover:text-slate-500 rounded-full"
              onClick={() => {
                handleUpdate(id);
                analytics.track("table_edit_button_clicked", {
                  id,
                });
              }}
            >
              <span className="sr-only">Edit</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z" />
              </svg>
            </button>
          )}
          {deleteAction && (
            <button
              className="text-rose-500 hover:text-rose-600 rounded-full"
              onClick={() => {
                handleDelete(id);
                analytics.track("table_delete_button_clicked", {
                  id,
                });
              }}
            >
              <span className="sr-only">Delete</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                <path d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
              </svg>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
}

export default AssetDashTableItem;
