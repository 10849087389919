import { createContext, useContext, useState } from "react"
import { toast } from "react-hot-toast"
import {
  getListMembers,
  postAddMembers,
  postRemoveMembers,
  postRemoveMultipleMembers,
  readMember,
} from "../../api/members"
import { useAuth } from "./useAuth"

export const MembersContext = createContext({});

export function useMembers() {
  return useContext(MembersContext);
}

export const MembersProvider = ({ children }) => {
  const { logout } = useAuth();
  const [members, setMembers] = useState(null);
  const [memberDetail, setMemberDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [membersStatActive, setMembersStatActive] = useState("active");

  const addMembers = async (identifier) => {
    try {
      setIsActionLoading(true);
      const resp = await postAddMembers(identifier);
      setIsActionLoading(false);
      if (resp.status === 200) {
        toast.success("Member added successfully!");
        return resp.data;
      }
    } catch (error) {
      setIsActionLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.detail);
    }
  };

  const removeMembers = async (identifier) => {
    try {
      setIsActionLoading(true);
      const resp = await postRemoveMembers(identifier);
      setIsActionLoading(false);
      if (resp.status === 200) {
        toast.success("Member Removed successfully");
      }
    } catch (error) {
      setIsActionLoading(false);
      console.log(error);
    }
  };

  const removeMultipleMembers = async (membersList) => {
    try {
      setIsActionLoading(true);
      const resp = await postRemoveMultipleMembers(membersList);
      setIsActionLoading(false);
      if (resp.status === 200) {
        toast.success("Members Removed successfully");
      }
    } catch (error) {
      setIsActionLoading(false);
      console.log(error);
    }
  };

  const fetchListOfMembers = async (page, item) => {
    try {
      setIsLoading(true);
      const resp = await getListMembers({ page: page, status: item });

      if (resp.status === 200) {
        if (page === 1) {
          setMembers(resp.data.members);
        } else {
          setMembers((prevState) => [...prevState, ...resp.data.members]);
        }
      }
      setMembersStatActive(item);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        await logout();
      }
    }
  };

  const fetchMemberDetail = async (identifier) => {
    try {
      setIsActionLoading(true);
      const resp = await readMember(identifier);
      if (resp.status === 200) {
        setMemberDetail(resp.data);
      }
      setIsActionLoading(false);
    } catch (error) {
      setIsActionLoading(false);
      console.log(error);
    }
    try {
    } catch (error) {}
  };

  return (
    <MembersContext.Provider
      value={{
        members,
        isLoading,
        isActionLoading,
        fetchListOfMembers,
        removeMembers,
        addMembers,

        fetchMemberDetail,
        memberDetail,
        removeMultipleMembers,
        membersStatActive,
        setMembersStatActive,
      }}
    >
      {children}
    </MembersContext.Provider>
  );
};
