import { AsssetDashLogo } from "../AssetDashLogo"
import { LoginForm } from "./LoginForm"

export default function LoginPage() {
  return (
    <div className="py-22p">
      <div className="mb-20 space-y-2">
        <AsssetDashLogo size="large" forceDark={true} />
        <p className="text-bold text-xl">Partner Portal</p>
      </div>
      <div className=" min-h-screen">
        <LoginForm />
      </div>
    </div>
  );
}
