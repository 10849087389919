import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../app/hooks/useAuth";
import { useLearn } from "../../app/hooks/useLearn";
import Builder from "../../components/Course/CourseDetails/Builder";
import CourseDetailsSection from "../../components/Course/CourseDetails/CourseDetailsSection";
import { ShimmerPostDetails } from "react-shimmer-effects-18";
import { toast } from "react-hot-toast";

const courseDetailsTabs = ["details", "builder"];
export default function CourseDetailsPage() {
  const { id } = useParams();
  const {
    isLoading,
    fetchCourseDetails,
    courseDetails,
    activeTab,
    setActiveTab,
  } = useLearn();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.organization && !user.organization.permission_learn) {
      navigate("/community");
    }
  }, [user]);

  useMemo(() => {
    if (id) {
      fetchCourseDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (courseDetails) {
      const { status } = courseDetails;

      if (status === "active") {
        setActiveTab("details");
      } else if (status === "created") {
        setActiveTab("builder");
      }
    }
  }, [id, courseDetails]);

  const { status } = courseDetails || "";

  return (
    <div className="text-left" key={id}>
      {!isLoading ? (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <div>
            {/* Start */}
            <div className="relative mb-4">
              <div
                className="absolute bottom-0 w-full h-px bg-slate-200"
                aria-hidden="true"
              ></div>
              <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
                {courseDetailsTabs.map((courseDetailsTab) => {
                  return (
                    <li
                      key={courseDetailsTab}
                      onClick={() => {
                        analytics.track("course_details_tab_click", {
                          tabName: courseDetailsTab,
                        });
                        if (
                          courseDetailsTab === "builder" &&
                          (status === "active" || status === "review")
                        ) {
                          toast.error(
                            "Please deactivate the course if you want to edit!"
                          );
                        } else setActiveTab(courseDetailsTab);
                      }}
                      className="cursor-pointer mr-8 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
                    >
                      <p
                        className={`block pb-3 whitespace-nowrap capitalize ${
                          activeTab === courseDetailsTab
                            ? " text-indigo-500 border-b-2 border-indigo-500"
                            : "hover:text-slate-600"
                        } `}
                      >
                        {courseDetailsTab}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* End */}
          </div>
          {activeTab === "details" && <CourseDetailsSection />}
          {activeTab === "builder" && <Builder id={id} />}
        </div>
      ) : (
        <div>
          <ShimmerPostDetails card cta variant="EDITOR" />
        </div>
      )}
    </div>
  );
}
