const NTimesList = ({
  n,
  handleClick,
  item,
  data,
  activeNumber,
  showBorder,
}) => {
  let list = [];
  for (let i = 0; i < n; ++i) {
    list.push(
      <li
        key={i}
        onClick={() => {
          handleClick(item, i);
        }}
        className="cursor-pointer mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
      >
        <a
          className={`block pb-5 text-white whitespace-nowrap ${
            activeNumber === i && showBorder
              ? "border-b-2 border-indigo-500"
              : ""
          }`}
        >
          <span
            className={`flex w-7 h-6 text-center leading-6 justify-center rounded ${
              activeNumber === i && showBorder
                ? "bg-indigo-500"
                : data?.length <= i
                ? "bg-img-upload-border"
                : "bg-indigo-500"
            }`}
          >
            {i + 1}
          </span>
        </a>
      </li>
    );
  }

  return (
    <div className="relative">
      <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
        {list}
      </ul>
    </div>
  );
};

export default NTimesList;
