import { axiosAssetDashApi } from "../lib/api";

export const getGameStoreDetails = async () => {
  return await axiosAssetDashApi.get(
    `api_v1/games/game_store/details`
  );
};

export const postCreateGameStore = async (payload) => {
  return await axiosAssetDashApi.post("api_v1/games/game_store/create", payload);
};

export const postUpdateGameStore = async (formValues) => {
  return await axiosAssetDashApi.post("api_v1/games/game_store/update", formValues);
};

export const postSubmitGameStore = async (formValues) => {
  return await axiosAssetDashApi.post("api_v1/games/game_store/submit", formValues);
};

export const postDeactivateGameStore = async (formValues) => {
  return await axiosAssetDashApi.post(
    "api_v1/games/game_store/deactivate",
    formValues
  );
};


export const getListGames = async (payload) => {
  return await axiosAssetDashApi.get(
    `api_v1/games/games/list?status=${payload.status}&page=${payload.page}`
  );
};

export const getGameDetails = async (identifier) => {
  return await axiosAssetDashApi.get(
    `api_v1/games/game/details?partner_game_id=${identifier}`
  );
};

export const postUploadImage = async (imageDataUrl) => {
  return await axiosAssetDashApi.post(
    "api_v1/games/upload_image",
    imageDataUrl,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const postCreateGame = async (payload) => {
  return await axiosAssetDashApi.post("api_v1/games/game/create", payload);
};

export const postUpdateGame = async (formValues) => {
  return await axiosAssetDashApi.post("api_v1/games/game/update", formValues);
};

export const postSubmitGame = async (formValues) => {
  return await axiosAssetDashApi.post("api_v1/games/game/submit", formValues);
};

export const postDeactivateGame = async (formValues) => {
  return await axiosAssetDashApi.post(
    "api_v1/games/game/deactivate",
    formValues
  );
};

export const getGamePasses = async (identifier, page, limit) => {
  return await axiosAssetDashApi.get(
    `api_v1/games/games/game_passes?partner_game_id=${identifier}`,
    { params: {page, limit}}
  );
};

export const getGameUserActions = async (identifier, page, limit) => {
  return await axiosAssetDashApi.get(
    `api_v1/games/games/game_user_actions?partner_game_id=${identifier}`, 
    { params: {page, limit}}
  );
};

export const getGameUserActionWebhooks = async (identifier, page, limit) => {
  return await axiosAssetDashApi.get(
    `api_v1/games/games/game_user_action_webhooks?partner_game_id=${identifier}`,
    { params: {page, limit}}
  );
};