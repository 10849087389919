import { useEffect, useState } from "react";
import { useLearn } from "../../../app/hooks/useLearn";
import ModalBlank from "../../TailwindLibrary/actions/ModalBlank";
import NTimesList from "./NTimesList";

export default function TopNavigation() {
  const {
    courseDetails,
    stepNumber,
    setStepNumber,
    questionsNumber,
    setQuestionsNumber,
    activeSlideStepNumber,
    setActiveSlideNumber,
    activeQuestionStepNumber,
    setActiveQuestionNumber,
    stepActive,
    setStepActive,
    deactivateCourse,
    submitCourse,
    ctaNumber,
    setCTANumber,
  } = useLearn();

  const [open, setOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const { id, steps, questions, cta } = courseDetails || {};

  useEffect(() => {
    if (stepActive === "slide")
      if (steps && steps.length > 5) {
        setStepNumber(steps.length);
      }
  }, [steps, stepActive]);

  useEffect(() => {
    if (stepActive === "question")
      if (questions && questions.length > 5) {
        setQuestionsNumber(questions.length);
      }
  }, [questions, stepActive]);

  const handleClick = (item, i) => {
    if (item === "slide") {
      analytics.track("course_details_builder_slide_number_click", {
        id: id,
        num: i + 1,
      });
      setStepActive("slide");
      localStorage.setItem("stepActive", "slide");
      if (steps.length >= i) setActiveSlideNumber(i);
    }
    if (item === "question") {
      analytics.track("course_details_builder_question_number_click", {
        id: id,
        num: i + 1,
      });
      setStepActive("question");
      localStorage.setItem("stepActive", "question");
      if (questions.length >= i) setActiveQuestionNumber(i);
    }
    if (item === "cta") {
      analytics.track("course_details_builder_cta_click", {
        id: id,
      });
      setStepActive("cta");
      localStorage.setItem("stepActive", "cta");
    }
  };

  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4`}>
      <div className="mx-0 lg:mx-auto flex justify-between md:justify-start lg:justify-between space-x-4 items-start pt-4">
        <p
          className={`font-medium text-sm ${
            stepActive === "slide" ? "text-indigo-500" : "text-black"
          }`}
        >
          Slide
        </p>

        <div className="">
          <NTimesList
            n={stepNumber}
            handleClick={handleClick}
            data={steps}
            item={"slide"}
            activeNumber={activeSlideStepNumber}
            showBorder={stepActive === "slide" ? true : false}
          />
        </div>
      </div>
      <div className="mx-0 lg:mx-auto flex justify-between md:justify-start lg:justify-between space-x-4 items-start pt-4">
        <p
          className={`font-medium text-sm ${
            stepActive === "question" ? "text-indigo-500" : "text-black"
          }`}
        >
          Questions
        </p>
        <div className="flex items-center">
          <NTimesList
            n={questionsNumber}
            handleClick={handleClick}
            data={questions}
            item={"question"}
            activeNumber={activeQuestionStepNumber}
            showBorder={stepActive === "question" ? true : false}
          />
        </div>
      </div>
      {
        <div className="mx-0 lg:mx-auto flex justify-between md:justify-start lg:justify-between space-x-4 items-start pt-4">
          <p
            className={`font-medium text-sm ${
              stepActive === "cta" ? "text-indigo-500" : "text-black"
            }`}
          >
            CTA
          </p>
          <div className="flex items-center">
            <NTimesList
              n={ctaNumber}
              handleClick={handleClick}
              data={cta}
              item={"cta"}
              activeNumber={0}
              showBorder={stepActive === "cta" ? true : false}
            />
          </div>
        </div>
      }

      {(courseDetails?.status === "active" ||
        courseDetails?.status === "review") && (
        <div className="ml-0 lg:ml-auto relative top-0 md:top-2 mb-4">
          <button
            className="btn-sm bg-primary-red py-2 px-4 text-white font-bold w-full md:w-max"
            aria-controls="danger-modal"
            onClick={(e) => {
              // await deactivateCourse({ course_id: courseDetails?.id });
              e.stopPropagation();
              setOpen(true);
              analytics.track("course_details_deactivate_button_click", {
                id: id,
              });
            }}
          >
            Deactivate
          </button>
        </div>
      )}
      <ModalBlank id="danger-modal" modalOpen={open} setModalOpen={setOpen}>
        <div className="p-5 flex space-x-4">
          {/* Icon */}
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
            <svg
              className="w-4 h-4 shrink-0 fill-current text-rose-500"
              viewBox="0 0 16 16"
            >
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
            </svg>
          </div>
          {/* Content */}
          <div className="w-full">
            {/* Modal header */}
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800">
                Are you sure you want to deactivate the course?
              </div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <p>
                  Deactivating the course will remove this from visibility for
                  all users. No new users will be able to take this course.
                </p>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end space-x-2">
              <button
                className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
                onClick={async () => {
                  await deactivateCourse({ course_id: courseDetails?.id });
                  setOpen(false);
                }}
              >
                Yes, Deactivate it
              </button>
            </div>
          </div>
        </div>
      </ModalBlank>

      {courseDetails?.status !== "active" &&
        courseDetails?.status !== "review" && (
          <div className="ml-0 lg:ml-auto relative top-0 md:top-2 mb-4">
            <button
              aria-controls="success-modal"
              className="btn-sm bg-price-green py-2 px-4 text-black font-bold w-full md:w-max"
              onClick={async (e) => {
                e.stopPropagation();
                setSuccessModalOpen(true);
              }}
            >
              Submit
            </button>
            <ModalBlank
              id="success-modal"
              modalOpen={successModalOpen}
              setModalOpen={setSuccessModalOpen}
            >
              <div className="p-5 flex space-x-4">
                {/* Icon */}
                <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-emerald-100">
                  <svg
                    className="w-4 h-4 shrink-0 fill-current text-emerald-500"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                  </svg>
                </div>
                {/* Content */}
                <div>
                  {/* Modal header */}
                  <div className="mb-2">
                    <div className="text-lg font-semibold text-slate-800">
                      Are you sure you want to submit this course to review?
                    </div>
                  </div>
                  {/* Modal content */}
                  <div className="text-sm mb-10">
                    <div className="space-y-2">
                      <p>
                        You cannot make edits after submission, you will need to
                        remove the course from review in order to make changes.
                      </p>
                    </div>
                  </div>
                  {/* Modal footer */}
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSuccessModalOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                      onClick={async () => {
                        analytics.track(
                          "course_details_submit_button_clicked",
                          {
                            id: courseDetails?.id,
                          }
                        );
                        await submitCourse({ course_id: courseDetails?.id });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </ModalBlank>
          </div>
        )}
    </div>
  );
}
