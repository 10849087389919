export const getBearerToken = () => {
  if (typeof localStorage === "undefined") {
    return "Bearer";
  }
  const token = localStorage.getItem("token");
  return token ? `Bearer ${token}` : "Bearer";
};

export const setBearerToken = async (config: any) => {
  const token = getBearerToken();
  // if token exists add it to api requests
  config.headers.Authorization = token;
  // config.headers.common["Authorization"] = token;
  return config;
};

export const handleConfigError = (error: any) => {
  return Promise.reject(error);
};
