import { useEffect, useState } from "react";
import DeleteButton from "../../components/TailwindLibrary/actions/DeleteButton";
import ModalAction from "../../components/TailwindLibrary/actions/ModalAction";
import { useMembers } from "../../app/hooks/useMembers";
import AssetDashTable from "../../components/Table/AssetDashTable";
import { ShimmerTable } from "react-shimmer-effects-18";
import { postRemoveMultipleMembers } from "../../api/members";
import { useAuth } from "../../app/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import PaginationClassic from "../../components/TailwindLibrary/PaginationClassic";

const membersStatus = ["active", "invited", "inactive"];

export default function CommunityPage() {
  const {
    fetchListOfMembers,
    members,
    addMembers,
    isActionLoading,
    isLoading,
    removeMembers,
    membersStatActive,
  } = useMembers();
  const [selectedItems, setSelectedItems] = useState([]);
  const [openInvite, setOpenInvite] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [page, setPage] = useState(1);

  const isNextPage = 100 * page <= members?.length;

  const handleNextPage = () => {
    if (!isLoading && isNextPage) {
      setPage(page + 1);
      analytics.track("community_next_button_clicked");
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      analytics.track("community_previous_button_clicked");
    }
  };

  const { user } = useAuth();
  const navigate = useNavigate();

  const handleStatusChange = async (item) => {
    await fetchListOfMembers(page, item);
  };

  useEffect(() => {
    if (user && user.organization && !user.organization.permission_community) {
      navigate("/settings/users");
    }
  }, [user]);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  useEffect(() => {
    if (page && membersStatActive) fetchListOfMembers(page, membersStatActive);
  }, [page]);

  const handleAddMember = async () => {
    if (!isActionLoading) {
      analytics.track("community_add_member_submitted", {
        walletAddress,
      });
      await addMembers(walletAddress);
      setWalletAddress("");
      await fetchListOfMembers(page, membersStatActive);
      setOpenInvite(false);
    }
  };

  const handleUpdate = async (identifier) => {
    console.log("====================================");
    console.log("update me", identifier);
    console.log("====================================");
  };

  const handleDelete = async (identifier) => {
    await removeMembers(identifier);
    await fetchListOfMembers(page, membersStatActive);
  };

  const handleMultipleDelete = async (e) => {
    e.preventDefault();
    await postRemoveMultipleMembers(selectedItems);
    setSelectedItems([]);
    await fetchListOfMembers(page, membersStatActive);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Community
          </h1>
        </div>

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* Delete button */}
          <DeleteButton
            selectedItems={selectedItems}
            handleMultipleDelete={handleMultipleDelete}
          />

          {/* Add customer button */}
          <button
            className="btn bg-price-green text-black"
            aria-controls="add-user-modal"
            onClick={(e) => {
              e.stopPropagation();
              setOpenInvite(true);
              analytics.track("community_add_member_button_clicked");
            }}
          >
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="block ml-2">Add Member</span>
          </button>
          <ModalAction
            id="add-user-modal"
            modalOpen={openInvite}
            setModalOpen={setOpenInvite}
          >
            {/* Modal header */}
            <div className="mb-2 text-center">
              <div className="text-lg font-semibold text-slate-800">
                Add Community Member
              </div>
            </div>
            {/* Modal content */}
            <div className="text-center">
              {/* Submit form */}
              <form
                className="flex max-w-sm m-auto"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddMember();
                }}
              >
                <div className="grow mr-2">
                  <label htmlFor="subscribe-form" className="sr-only">
                    Leave your Wallet Address
                  </label>
                  <input
                    id="subscribe-form"
                    className="form-input w-full px-2 py-1 text-sm"
                    type="text"
                    value={walletAddress}
                    placeholder="Enter the Wallet Address"
                    onChange={(e) => setWalletAddress(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="btn-sm bg-price-green text-white whitespace-nowrap"
                >
                  Invite
                </button>
              </form>
              {/* <div className="text-xs text-slate-500 italic mt-3">
                I respect your privacy. No spam. Unsubscribe at any time!
              </div> */}
            </div>
          </ModalAction>
        </div>
      </div>

      <div>
        {/* Start */}
        <div className="relative mb-8">
          <div
            className="absolute bottom-0 w-full h-px bg-slate-200"
            aria-hidden="true"
          ></div>
          <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
            {membersStatus.map((memberStatus) => {
              return (
                <li
                  key={memberStatus}
                  onClick={() => {
                    setPage(1);
                    handleStatusChange(memberStatus);
                    analytics.track("community_status_tab_clicked", {
                      tabName: memberStatus,
                    });
                  }}
                  className="cursor-pointer mr-8 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
                >
                  <p
                    className={`block pb-3 whitespace-nowrap capitalize ${
                      membersStatActive === memberStatus
                        ? " text-indigo-500 border-b-2 border-indigo-500 font-bold"
                        : "hover:text-slate-600 font-medium"
                    } `}
                  >
                    {memberStatus}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        {/* End */}
      </div>

      {!isLoading ? (
        <div>
          <AssetDashTable
            data={members}
            selectedItems={handleSelectedItems}
            identifier="member_identifier"
            text={"Community Members"}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            enableSelection={true}
          />
          <div className="mt-8">
            <PaginationClassic
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              page={page}
              isNextPage={isNextPage}
              data={members}
            />
          </div>
        </div>
      ) : (
        <div className="bg-white">
          <ShimmerTable row={3} col={4} />
        </div>
      )}
    </div>
  );
}
