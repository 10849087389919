import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../app/hooks/useAuth"
import APIKeyPanel from "../../components/APIKey/APIKeyPanel"
import SettingsSidebar from "../../components/TailwindLibrary/settings/SettingsSidebar"

export default function APIKeysPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.organization && !user.organization.permission_api) {
      navigate("/settings/users");
    }
  }, [user]);
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Content */}
      <div className="bg-white shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <SettingsSidebar />
          <APIKeyPanel />
        </div>
      </div>
    </div>
  );
}
