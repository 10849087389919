import { useEffect } from "react";
import { useLearn } from "../../../app/hooks/useLearn";
import { CheckIcon } from "../../Icon/CheckIcon";

export default function AddQuestionDetails({ err, setErr }) {
  const {
    question,
    setQuestion,
    answerA,
    setAnswerA,
    answerB,
    setAnswerB,
    answerC,
    setAnswerC,
    answerD,
    setAnswerD,
    selectedAnswer,
    setSelectedAnswer,
    activeQuestionStepNumber,
  } = useLearn();

  useEffect(() => {
    setErr("");
  }, [answerA, answerB, selectedAnswer]);

  return (
    <div className="grid grid-cols-8">
      <div className="px-4 py-3">
        <p
          className={`wrap-square text-box-text rounded-sm font-medium border-1 border-solid border-gray-text
        `}
        >
          {activeQuestionStepNumber + 1}
        </p>
      </div>
      <div className="col-span-7 flex flex-col space-y-4 justify-center overflow-scroll scroller">
        <div>
          <input
            type="text"
            placeholder="Enter the Question here"
            value={question}
            className="rounded-lg w-64 md:w-96 border-none text-lg md:text-2xl py-2 px-4"
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>
        <div className="flex space-x-4 items-center">
          <input
            type="text"
            placeholder="Enter the Answer Option here"
            value={answerA}
            className={`rounded-lg w-56 md:w-96 h-12 border-none py-2 px-4 text-sm ${
              selectedAnswer === "a"
                ? "bg-light-green"
                : "bg-indigo-100 text-txt-indigo"
            }`}
            onChange={(e) => setAnswerA(e.target.value)}
          />
          <div
            className={`flex h-full w-8 cursor-pointer rounded-sm ${
              selectedAnswer === "a" ? "bg-light-green" : "bg-img-upload-border"
            }`}
            onClick={() => {
              setSelectedAnswer("a");
            }}
          >
            <div className="m-auto">
              <CheckIcon
                height="20"
                width="16"
                fill={selectedAnswer === "a" ? "#21CE99" : "#94A3B8"}
                margin="16"
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-4 items-center">
          <input
            type="text"
            placeholder="Enter the Answer Option here"
            value={answerB}
            className={`rounded-lg w-56 md:w-96 h-12 border-none py-2 px-4 text-sm ${
              selectedAnswer === "b"
                ? "bg-light-green"
                : "bg-indigo-100 text-txt-indigo"
            }`}
            onChange={(e) => setAnswerB(e.target.value)}
          />
          <div
            className={`flex h-full w-8 cursor-pointer rounded-sm ${
              selectedAnswer === "b" ? "bg-light-green" : "bg-img-upload-border"
            }`}
            onClick={() => {
              setSelectedAnswer("b");
            }}
          >
            <div className="m-auto">
              <CheckIcon
                height="20"
                width="16"
                fill={selectedAnswer === "b" ? "#21CE99" : "#94A3B8"}
                margin="16"
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-4 items-center">
          <input
            type="text"
            placeholder="Enter the Answer Option here (Optional)"
            value={answerC}
            className={`rounded-lg w-56 md:w-96 h-12 border-none py-2 px-4 text-sm ${
              selectedAnswer === "c"
                ? "bg-light-green"
                : "bg-indigo-100 text-txt-indigo"
            }`}
            onChange={(e) => setAnswerC(e.target.value)}
          />
          <div
            className={`flex h-full w-8 cursor-pointer rounded-sm ${
              selectedAnswer === "c" ? "bg-light-green" : "bg-img-upload-border"
            }`}
            onClick={() => {
              setSelectedAnswer("c");
            }}
          >
            <div className="m-auto">
              <CheckIcon
                height="20"
                width="16"
                fill={selectedAnswer === "c" ? "#21CE99" : "#94A3B8"}
                margin="16"
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-4 items-center pb-8">
          <input
            type="text"
            placeholder="Enter the Answer Option here (Optional)"
            value={answerD}
            className={`rounded-lg w-56 md:w-96 h-12 border-none py-2 px-4 text-sm ${
              selectedAnswer === "d"
                ? "bg-light-green"
                : "bg-indigo-100 text-txt-indigo"
            }`}
            onChange={(e) => setAnswerD(e.target.value)}
          />
          <div
            className={`flex h-full w-8 cursor-pointer rounded-sm ${
              selectedAnswer === "d" ? "bg-light-green" : "bg-img-upload-border"
            }`}
            onClick={() => {
              setSelectedAnswer("d");
            }}
          >
            <div className="m-auto">
              <CheckIcon
                height="20"
                width="16"
                fill={selectedAnswer === "d" ? "#21CE99" : "#94A3B8"}
                margin="16"
              />
            </div>
          </div>
        </div>
        <div>{err && <p>{err}</p>}</div>
      </div>
    </div>
  );
}
