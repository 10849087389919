import { axiosAssetDashApi } from "../lib/api";

export const postCreateLearnSubscription = async (formValues) => {
    return await axiosAssetDashApi.post(
      `api_v1/learn/create_subscription`,
      formValues
    );
  };

  export const postEnableCTA = async (formValues) => {
    return await axiosAssetDashApi.post(
      `api_v1/learn/enable_cta`,
      formValues
    );
  };
