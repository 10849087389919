import { useState } from "react";
import {
  CardElement,
  useElements,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  postCreateLearnSubscription,
  postEnableCTA,
} from "../../../api/learnApi";
import { toast } from "react-hot-toast";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

function CheckoutForm(props) {
  const { learnCourseId, onSuccess } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isButtonClick, setIsButtonClick] = useState(false);

  const createSubscription = async () => {
    try {
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement),
        billing_details: {
          name,
          email,
        },
      });

      const createSubResp = await postCreateLearnSubscription({
        learn_course_id: learnCourseId,
        payment_method: paymentMethod?.paymentMethod?.id,
        name,
        email,
      });

      const confirmPayment = await stripe?.confirmCardPayment(
        createSubResp.data.client_secret
      );

      if (confirmPayment?.error) {
        toast.error(confirmPayment.error.message);
      } else {
        const response = await postEnableCTA({
          learn_course_id: learnCourseId,
        });
        onSuccess();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.detail);
    }
  };

  return (
    <div className="space-y-4">
      <p className="text-black text-xl font-bold">
        Subscribe today and enable your call to action!
      </p>
      <div className="space-y-4 text-black bg-white shadow-lg rounded-md border border-slate-200 p-2">
        <input
          placeholder="Name"
          type="text"
          value={name}
          className="border-b-1 border-solid w-full py-2 px-4 outline-none"
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        <input
          placeholder="Email"
          type="text"
          value={email}
          className="border-b-1 border-solid w-full py-2 px-4 outline-none"
          onChange={(e) => setEmail(e.target.value)}
        />
        <CardElement className="border-b-1 border-solid w-full py-2 px-4 outline-none" />
        <button
          className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white flex space-x-2 w-full"
          onClick={async () => {
            if (!isButtonClick) {
              setIsButtonClick(true);
              await createSubscription();
              setIsButtonClick(false);
            }
          }}
        >
          <p>Upgrade</p>
          {isButtonClick && (
            <svg
              className="animate-spin  h-5 w-5 text-white"
              style={{ left: "54%" }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx={12}
                cy={12}
                r={10}
                stroke="currentColor"
                strokeWidth={4}
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
}

function SubscribeButton(props) {
  const { learnCourseId, onSuccess } = props;
  const [isButtonClick, setIsButtonClick] = useState(false);

  const stripe = useStripe();

  const createSubscription = async () => {
    try {
      const createSubResp = await postCreateLearnSubscription({
        learn_course_id: learnCourseId,
      });

      const confirmPayment = await stripe?.confirmCardPayment(
        createSubResp.data.client_secret
      );

      if (confirmPayment?.error) {
        toast.error(confirmPayment.error.message);
      } else {
        await postEnableCTA({
          learn_course_id: learnCourseId,
        });
        onSuccess();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.detail);
    }
  };

  return (
    <>
      <p className="text-black text-xl font-bold">
        Subscribe today and enable your call to action!
      </p>
      <p className="text-black text-sm font-medium my-2">
        This subscription will be charged to the credit card you have on file.
      </p>
      <div className="my-4">
        <button
          className="flex space-x-2 w-full btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={async () => {
            if (!isButtonClick) {
              setIsButtonClick(true);
              await createSubscription();
              setIsButtonClick(false);
            }
          }}
        >
          <p>Upgrade</p>
          {isButtonClick && (
            <svg
              className="animate-spin  h-5 w-5 text-white"
              style={{ left: "54%" }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx={12}
                cy={12}
                r={10}
                stroke="currentColor"
                strokeWidth={4}
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
        </button>
      </div>
    </>
  );
}

export function LearnCTACheckout(props) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
}

export function LearnCTASubscribeButton(props) {
  return (
    <Elements stripe={stripePromise}>
      <SubscribeButton {...props} />
    </Elements>
  );
}
