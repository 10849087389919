import React, { useEffect } from "react";
import { useLearn } from "../../../app/hooks/useLearn";
import { CheckIcon } from "../../Icon/CheckIcon";
import ImageUploading from "react-images-uploading";
import DropdownFull from "../../TailwindLibrary/DropdownFull";
import { GifIcon, GiftIcon } from "@heroicons/react/20/solid";
import { isValidUrl } from "../../../utils/Utils";

export default function AddCTADetails({ err, setErr }) {
  const {
    ctaTitle,
    setCTATitle,
    ctaText,
    setCTAText,
    ctaUrl,
    setCtaUrl,
    ctaUploadImage,
    setCTAUploadImage,
    courseDetails,
    selectedCTADropdown,
  } = useLearn();

  const { is_cta_enabled } = courseDetails || false;

  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    setCTAUploadImage([]);
    setCTAUploadImage(imageList || []);
  };

  const fileName =
    ctaUploadImage && ctaUploadImage.length > 0 && ctaUploadImage[0]?.file
      ? ctaUploadImage[0]?.file?.name
      : ctaUploadImage[0]
      ? ""
      : "No Selected File";

  useEffect(() => {
    setErr("");
  }, [ctaTitle, ctaText, ctaUrl, ctaUploadImage]);

  return (
    <div>
      {is_cta_enabled ? (
        <div>
          <div className="flex flex-col space-y-4 justify-center overflow-scroll scroller">
            <div>
              <input
                type="text"
                placeholder="Enter the title"
                value={ctaTitle}
                className="rounded-lg w-64 md:w-96 border text-lg py-2 px-4"
                onChange={(e) => setCTATitle(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Enter the text"
                value={ctaText}
                className="rounded-lg w-64 md:w-96 border text-lg py-2 px-4"
                onChange={(e) => setCTAText(e.target.value)}
              />
            </div>
            <div>
              <ImageUploading
                multiple
                value={ctaUploadImage}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={["jpg", "jpeg", "svg", "png"]}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div>
                    <div
                      className="h-36 border-2 border-dashed border-img-upload-border"
                      onClick={() => {
                        onChange();
                        onImageUpload();
                      }}
                      {...dragProps}
                    >
                      <svg
                        className="mx-auto mt-8"
                        width="54"
                        height="39"
                        viewBox="0 0 54 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.8147 38.6977C11.5467 38.4864 8.44151 37.411 5.94254 35.625C3.44358 33.839 1.67861 31.4338 0.899661 28.7527C0.120708 26.0717 0.367599 23.252 1.60508 20.6961C2.84256 18.1403 5.00736 15.9791 7.79037 14.521C8.38253 10.6328 10.6369 7.0595 14.1317 4.46988C17.6264 1.88026 22.122 0.451782 26.7771 0.451782C31.4322 0.451782 35.9278 1.88026 39.4226 4.46988C42.9173 7.0595 45.1717 10.6328 45.7639 14.521C48.5469 15.9791 50.7117 18.1403 51.9492 20.6961C53.1867 23.252 53.4336 26.0717 52.6546 28.7527C51.8757 31.4338 50.1107 33.839 47.6117 35.625C45.1128 37.411 42.0076 38.4864 38.7396 38.6977V38.736H14.8147V38.6977ZM29.1696 22.6182H36.3471L26.7771 12.5446L17.2072 22.6182H24.3847V30.6771H29.1696V22.6182Z"
                          fill="#21CE99"
                        />
                      </svg>
                      <button
                        style={isDragging ? { color: "red" } : null}
                        className="w-full text-center font-bold"
                      >
                        {ctaUploadImage && ctaUploadImage.length === 0
                          ? "Browse Image to Upload"
                          : "Image Uploaded"}
                      </button>
                      <p className="text-sm text-center">
                        We recommend using a square image under 500 KB
                      </p>
                    </div>
                    <div
                      className="rounded-md mt-4 py-2 px-4"
                      style={{ background: "rgba(33, 206, 153, 0.2)" }}
                    >
                      <div className="flex justify-between items-center">
                        <svg
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.24288 17.0027H11.9136C12.341 17.0027 12.751 16.8329 13.0533 16.5306C13.3555 16.2283 13.5253 15.8184 13.5253 15.3909V5.72023L8.69001 0.884888H2.24288C1.81541 0.884888 1.40545 1.0547 1.10318 1.35697C0.800916 1.65923 0.631104 2.0692 0.631104 2.49667V15.3909C0.631104 15.8184 0.800916 16.2283 1.10318 16.5306C1.40545 16.8329 1.81541 17.0027 2.24288 17.0027ZM7.88412 2.49667L11.9136 6.52612H7.88412V2.49667ZM4.25761 8.1379C4.41641 8.13795 4.57364 8.16928 4.72033 8.2301C4.86703 8.29092 5.0003 8.38004 5.11255 8.49236C5.2248 8.60469 5.31383 8.73802 5.37455 8.88475C5.43527 9.03149 5.4665 9.18874 5.46644 9.34754C5.46639 9.50634 5.43506 9.66357 5.37424 9.81027C5.31342 9.95696 5.22431 10.0902 5.11198 10.2025C4.99966 10.3147 4.86632 10.4038 4.71959 10.4645C4.57286 10.5252 4.4156 10.5564 4.2568 10.5564C3.93609 10.5563 3.62856 10.4288 3.40186 10.2019C3.17516 9.97506 3.04786 9.66745 3.04797 9.34674C3.04808 9.02602 3.17558 8.71849 3.40243 8.49179C3.62928 8.26509 3.9369 8.13779 4.25761 8.1379ZM4.66055 12.1674L5.94756 13.2658L7.88412 9.74968L11.1077 14.585H3.04877L4.66055 12.1674Z"
                            fill="#21CE99"
                          />
                        </svg>
                        <div className="flex space-x-2 items-center">
                          <p className="text-sm max-w-40 add-overflow-dot">
                            {fileName}
                          </p>
                          <svg
                            onClick={() => onImageRemove(0)}
                            className="cursor-pointer"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.1465 1.94373C12.1465 1.34373 11.7465 0.943726 11.1465 0.943726H5.14648C4.54648 0.943726 4.14648 1.34373 4.14648 1.94373V3.94373H0.146484V5.94373H1.14648V15.9437C1.14648 16.5437 1.54648 16.9437 2.14648 16.9437H14.1465C14.7465 16.9437 15.1465 16.5437 15.1465 15.9437V5.94373H16.1465V3.94373H12.1465V1.94373ZM6.14648 2.94373H10.1465V3.94373H6.14648V2.94373ZM13.1465 14.9437V5.94373H3.14648V14.9437H13.1465Z"
                              fill="#334155"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </ImageUploading>
            </div>
            <DropdownFull />
            <div>
              <input
                type="text"
                placeholder={
                  selectedCTADropdown === "Url"
                    ? "Enter the Url"
                    : "Enter the ID"
                }
                value={ctaUrl}
                className="rounded-lg w-64 md:w-96 border text-lg py-2 px-4"
                onChange={(e) => setCtaUrl(e.target.value)}
                onBlur={(e) => {
                  if (selectedCTADropdown === "Url") {
                    const resp = isValidUrl(e.target.value);
                    if (!resp) {
                      setErr("Enter a valid URL");
                    }
                  }
                }}
              />
            </div>
            <div>{err && <p>{err}</p>}</div>
          </div>
        </div>
      ) : (
        <div className="px-4 lg:px-14 py-6 bg-cta-box rounded-10 text-white my-8 text-center">
          <div className="space-y-2 items-center mx-auto">
            <p className="text-3xl font-medium">Call To Action Slide</p>
            <div className="text-lg">
              <p>Direct members to your preferred</p>{" "}
              <p>channel or landing page</p>
            </div>
            <img
              src="/images/cta_slide.png"
              alt="cta "
              className="w-56 h-auto object-cover mx-auto"
            />
            <div className="flex items-center justify-center space-x-2">
              <GiftIcon className="h-8 w-8" />
              <p className="text-xl font-medium">Premium Only</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
