import { Navigate } from "react-router-dom"

export const UnProtectedRoute = ({ children }) => {
  const resp = localStorage.getItem("token");
  if (resp) {
    return <Navigate to="/learn" replace />;
  }

  return children;
};
