import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useAuth } from "../app/hooks/useAuth.js"

import Header from "./Headers/Header.jsx"
import Sidebar from "./Sidebars/Sidebar.jsx"

export const Screen = ({ children }) => {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 40) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const { fetchUserDetails, isLoggedIn } = useAuth();
  useEffect(() => {
    if (isLoggedIn) fetchUserDetails();
  }, [isLoggedIn]);

  //   const router = useRouter();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const isMemberCenter = location.pathname === "/member-center";

  return (
    <div className="screen scroller">
      {isLoggedIn && (
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      )}
      <div className="w-full border border-slate-200">
        <main className="height-new">
          <>
            {isLoggedIn && (
              <Header
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />
            )}
            {children}
          </>
        </main>
        {/* <Footer isLoggedIn={false} /> */}
      </div>
    </div>
  );
};
